/// References
/// https://developers.google.com/maps/documentation/javascript/style-reference#style-features

export const DATA_CONST = {
    dayOptions: ['Today', 'Tomorrow', 'Yesterday'],
    localApis: {
        fuelPrices: 'http://localhost:7071/api/fuelprices',
        versions: 'http://localhost:7071/api/versions',
        alerts: 'http://localhost:7071/api/alerts',
        chart: 'http://localhost:7071/api/chart'
    },
    defaultMapCenter: {
        lat: -31.9505,
        lng: 115.8605
    },
    defaultMapBoundRestriction: {
        latLngBounds: {
            south: -36.065835,
            west: 112.029373,
            north: -12.867939,
            east: 129.985519
        }
    },
    mapContainerStyle: {
        width: '100%',
        height: '90vh'
    },
    defaultMapOptions: {
        imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
    },
    defaultMapMarkerIcon: {
        path: 'images/default_brand.svg',
        width: 28,
        height: 28
    },
    defaultMapInfoPopup: {
        offsetInPixel: {
            width: 0,
            height: -15
        }
    },
    defaultMapFeatureStyles: {
        default: [],
        hide: [
            {
                featureType: 'poi',
                stylers: [{ visibility: 'off' }],
            },
            {
                featureType: 'landscape',
                stylers: [{ visibility: 'simplified' }],
            },
            {
                featureType: 'transit',
                stylers: [{ visibility: 'off' }],
            },
        ]
    },
    lastCheckedTtl: 3600000,
    chartDateOptions: [
        { value: 'last7days', text: 'Last 7 days' },
        { value: 'last14days', text: 'Last 14 days' },
        { value: 'last30days', text: 'Last 30 days' },
        { value: 'last3months', text: 'Last 3 months' },
        { value: 'last6months', text: 'Last 6 months' },
        { value: 'last9months', text: 'Last 9 months' },
        { value: 'last1year', text: 'Last 1 year' },
    ]
};