import { useState, useEffect, useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import useMediaQuery from '@mui/material/useMediaQuery';
import Collapse from '@mui/material/Collapse';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import NavBar from './components/NavBar';
import Footer from './components/Footer';
import InfoPanel from './components/InfoPanel';
import SearchBar from './components/SearchBar';
import SelectedFiltersPanel from './components/SelectedFiltersPanel';
import ErrorAlert from './components/ErrorAlert';

import { DATA_CONST } from './constants/data-constants';
import { appSettings } from './constants/setting-defaults';
import { GTAG_EVENTS } from './constants/gtag-events';

import { fetchFuelPricesData, fuelTypesData, fuelBrandsData, fuelRegionsData, fuelStateRegionsData, fetchAppVersion } from './services/data-service';
import { createApiQuery } from './services/url-service';
import CacheService, { NO_CACHING, initAppSettings } from './services/caching-service';
import { getLocality } from './services/location-service';
import GtagEventService from './services/gtag-service';

import { loadTheme } from './configurations/theme-helper';

import i18n from './i18n';

const apiUrl = process.env.NODE_ENV === 'production' ? process.env['REACT_APP_FPWA_API'] : DATA_CONST.localApis.fuelPrices;
const versionsApiUrl = process.env.NODE_ENV === 'production' ? process.env['REACT_APP_FPWA_VERSIONS_API'] : DATA_CONST.localApis.versions;

const fuelTypes = fuelTypesData();
const fuelBrands = fuelBrandsData();
const fuelRegions = fuelRegionsData();
const fuelStateRegions = fuelStateRegionsData();
const locTargetDefaultState = initAppSettings()?.locationTarget || appSettings.locationTarget.suburb;
const defaultViewDefaultState = initAppSettings()?.defaultView || appSettings.defaultView.table;
const defaultThemeState = initAppSettings()?.backgroundMode === appSettings.backgroundMode.dark || false;
const defaultLanguageState = initAppSettings()?.language || appSettings.language.en;
const defaultPreferredFuelTypeState = initAppSettings()?.preferredFuelType || appSettings.preferredFuelType.value;

let apiQuery = { day: DATA_CONST.dayOptions[0], product: defaultPreferredFuelTypeState };

const fetchData = async (day) => {
  const query = Object.assign({ ...apiQuery }, { day });
  return await fetchFuelPricesData(apiUrl, query);
};


function App() {
  const [locationTarget, setLocationTarget] = useState(locTargetDefaultState);
  const [defaultView, setDefaultView] = useState(defaultViewDefaultState);
  const [isDarkMode, setDarkMode] = useState(defaultThemeState);
  const [language, setLanguage] = useState(defaultLanguageState);
  const [preferredFuelType, setPreferredFuelType] = useState(defaultPreferredFuelTypeState);
  const [selectedFuelRegion, setSelectedFuelRegion] = useState('');
  const [fuelType, setFuelType] = useState(defaultPreferredFuelTypeState);
  const [fuelBrand, setFuelBrand] = useState('');
  const [showSurrounding, setShowSurrounding] = useState(false);
  const [suburb, setSuburbValue] = useState('');
  const [suburbLatLng, setSuburbLatLng] = useState(null);
  const [isSearchBarOpen, setSearchBarOpen] = useState(false);
  const [day, setDay] = useState(DATA_CONST.dayOptions[0]);
  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [selectedSuburbCleared, setSelectedSuburbCleared] = useState(false);
  const [filtersReset, setFiltersReset] = useState(false);
  const [currentLocaleInfo, setCurrentLocaleInfo] = useState('Unknown');
  const [currentLocation, setCurrentLocation] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [showNewVersionNotification, setShowNewVersionNotification] = useState();
  const routerLocation = useLocation();

  const theme = loadTheme(isDarkMode, language);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const applyLocationTarget = (e) => {
    if (e.target.value !== locationTarget)
      setSelectedFuelRegion('');

    setLocationTarget(e.target.value);
    CacheService.cacheItem(appSettings.cacheKey.settings, {
      locationTarget: e.target.value,
      defaultView: defaultView,
      backgroundMode: isDarkMode
        ? appSettings.backgroundMode.dark : appSettings.backgroundMode.light,
      language: language,
      preferredFuelType: preferredFuelType
    }, NO_CACHING);
    GtagEventService.logCustomEvent(GTAG_EVENTS.settings.searchScope, { searchScope: e.target.value });
  };

  const applyDefaultView = (e) => {
    setDefaultView(e.target.value);
    CacheService.cacheItem(appSettings.cacheKey.settings, {
      locationTarget: locationTarget,
      defaultView: e.target.value,
      backgroundMode: isDarkMode
        ? appSettings.backgroundMode.dark : appSettings.backgroundMode.light,
      language: language,
      preferredFuelType: preferredFuelType
    }, NO_CACHING);
    GtagEventService.logCustomEvent(GTAG_EVENTS.settings.view, { view: e.target.value });
  };

  const applyBackgroundMode = (e) => {
    setDarkMode(e.target.value === appSettings.backgroundMode.dark);
    CacheService.cacheItem(appSettings.cacheKey.settings, {
      locationTarget: locationTarget,
      defaultView: defaultView,
      backgroundMode: e.target.value,
      language: language,
      preferredFuelType: preferredFuelType
    }, NO_CACHING);
    GtagEventService.logCustomEvent(GTAG_EVENTS.settings.theme, { theme: e.target.value });
  };

  const applyLanguage = (e) => {
    setLanguage(e.target.value);
    CacheService.cacheItem(appSettings.cacheKey.settings, {
      locationTarget: locationTarget,
      defaultView: defaultView,
      backgroundMode: isDarkMode
        ? appSettings.backgroundMode.dark : appSettings.backgroundMode.light,
      language: e.target.value,
      preferredFuelType: preferredFuelType
    }, NO_CACHING);

    i18n.changeLanguage(e.target.value);
    GtagEventService.logCustomEvent(GTAG_EVENTS.settings.language, { language: e.target.value });
  };

  const applyPreferredFuelType = (e) => {
    setPreferredFuelType(e.target.value);
    CacheService.cacheItem(appSettings.cacheKey.settings, {
      locationTarget: locationTarget,
      defaultView: defaultView,
      backgroundMode: isDarkMode
        ? appSettings.backgroundMode.dark : appSettings.backgroundMode.light,
      language: language,
      preferredFuelType: e.target.value
    }, NO_CACHING);
    GtagEventService.logCustomEvent(GTAG_EVENTS.settings.preferredFuelType, { preferredFuelType: e.target.value });
  };

  const onSuburbCellClick = async (e) => {
    setLoaded(false);
    setSuburbValue(e.target.value);
    setShowSurrounding(true);
    GtagEventService.logCustomEvent(GTAG_EVENTS.table.inline.suburbLink, { suburb: e.target.value });
    apiQuery = createApiQuery(fuelType, fuelBrand, true, selectedFuelRegion, e.target.value, locationTarget);
    setData(await fetchData(day));
    setLoaded(true);
    GtagEventService.logViewSearchResults(apiQuery);
    window.scrollTo({
      top: 120,
      behavior: 'smooth'
    });
  };

  const search = async () => {
    setLoaded(false);
    setSearchBarOpen(p => !p);
    apiQuery = createApiQuery(fuelType, fuelBrand, showSurrounding, selectedFuelRegion, suburb, locationTarget);
    GtagEventService.logSearch(apiQuery);
    setData(await fetchData(day));
    setLoaded(true);
    GtagEventService.logViewSearchResults(apiQuery);
    window.scrollTo({
      top: window.scrollY + 120,
      behavior: 'smooth'
    });
  };

  const reset = () => {
    setSearchBarOpen(p => !p);
    setSuburbValue('');
    setSelectedSuburbCleared(true);
    setSelectedFuelRegion('');
    setFuelType('1');
    setFuelBrand('');
    setShowSurrounding(false);
    setFiltersReset(true);
    GtagEventService.logCustomEvent(GTAG_EVENTS.search.reset);
  };

  const onClearFilter = (e) => {
    if (e === 'suburb') {
      setSuburbValue('');
      setSelectedSuburbCleared(true);
    }
    else if (e === 'fuelRegion')
      setSelectedFuelRegion('');
    else if (e === 'fuelType')
      setFuelType('');
    else if (e === 'fuelBrand')
      setFuelBrand('');
    else if (e === 'surrounding')
      setShowSurrounding(false);
    else
      reset();

    setSearchBarOpen(true);
  };

  const onSnackbarClose = (e) => {
    window.location.reload();
    setShowNewVersionNotification(false);
    GtagEventService.logCustomEvent(GTAG_EVENTS.version);
  };

  const geocoder = useMemo(() => {
    if (currentLocation) {
      return new window.google.maps.Geocoder();
    }
  }, [currentLocation]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords;
          setCurrentLocation({ lat: latitude, lng: longitude });
        },
        error => {
          console.log(error.message);
        }
      );
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  }, []);

  useEffect(() => {
    if (geocoder && currentLocation) {
      geocoder.geocode({ location: currentLocation, language: 'en-AU' }, (results, status) => {
        if (status === 'OK') {
          setCurrentLocaleInfo(getLocality(results));
        } else {
          console.log('Geocoder failed due to:', status);
        }
      });
    }
  }, [geocoder, currentLocation]);

  useEffect(() => {
    if (geocoder && suburb && suburb !== '') {
      geocoder.geocode({address: suburb, language: 'en-AU', componentRestrictions: {country: 'au'}}, (results, status) => {
        if (status === 'OK') {
          setSuburbLatLng(results[0].geometry.location);
        } else {
          console.log('Geocoder failed due to:', status);
        }
      })
    }
  }, [geocoder, suburb])

  useEffect(() => {
    if (filtersReset) {
      apiQuery = createApiQuery('1', '', false, '', '', locTargetDefaultState);
      setFiltersReset(false);
    }

    fetchData(day).then(d => {
      setData(d);
      setLoaded(true);
    }).catch((e) => {
      setHasError(true);
      GtagEventService.logException(e.message, true);
    });
  }, [day, filtersReset]);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  useEffect(() => {
    // versions cache refreshed every hour
    const cache = CacheService.getCachedItem(appSettings.cacheKey.versions);

    if (!cache || !cache.appVersion) {
      fetchAppVersion(versionsApiUrl).then(v => {
        setShowNewVersionNotification(v > process.env['REACT_APP_VERSION']);
        CacheService.cacheItem(appSettings.cacheKey.versions, { appVersion: v, lastChecked: new Date() }, DATA_CONST.lastCheckedTtl);
      });
    }
  }, []);


  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <NavBar
        position='relative'
        isSmallScreen={isSmallScreen}
        locationTarget={locationTarget}
        defaultView={defaultView}
        isDarkMode={isDarkMode}
        language={language}
        currentLocaleInfo={currentLocaleInfo}
        fuelTypes={fuelTypes}
        preferredFuelType={preferredFuelType}
        applyLocationTarget={applyLocationTarget}
        applyDefaultView={applyDefaultView}
        applyBackgroundMode={applyBackgroundMode}
        onLanguageChange={applyLanguage}
        onFuelTypeChange={applyPreferredFuelType}
        onSearchBarOpen={() => setSearchBarOpen((p) => !p)}
      />

      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '80vh', justifyContent: 'flex-start' }}>
        <Outlet context={theme} />

        {routerLocation.pathname === '/' &&
          <>
            <Paper elevation={1} sx={{ bgcolor: isDarkMode ? 'background.searchBar.dark' : 'background.searchBar.light' }}>
              <SelectedFiltersPanel
                isSmallScreen={isSmallScreen}
                selectedFuelType={fuelType}
                selectedFuelBrand={fuelBrand}
                selectedFuelRegion={selectedFuelRegion}
                showSurrounding={showSurrounding}
                selectedSuburb={suburb}
                day={day}
                onToggleSearchBar={() => setSearchBarOpen((p) => !p)}
                onClearFilter={onClearFilter}
              />

              <Collapse in={!isSmallScreen || (isSmallScreen && isSearchBarOpen)} timeout={'auto'}>
                <SearchBar
                  fuelTypes={fuelTypes}
                  fuelBrands={fuelBrands}
                  fuelRegions={fuelRegions}
                  fuelStateRegions={fuelStateRegions}
                  locationTarget={locationTarget}
                  selectedFuelType={fuelType}
                  selectedFuelBrand={fuelBrand}
                  selectedFuelRegion={selectedFuelRegion}
                  showSurrounding={showSurrounding}
                  selectedSuburb={suburb}
                  selectedSuburbCleared={selectedSuburbCleared}
                  isSmallScreen={isSmallScreen}
                  onFuelTypeSelected={(e) => setFuelType(e.target.value)}
                  onFuelBrandSelected={(e) => setFuelBrand(e.target.value)}
                  onSurroundingChanged={(e) => setShowSurrounding(e.target.checked)}
                  onFuelRegionSelected={(e) => setSelectedFuelRegion(e.target.value)}
                  onSuburbSelected={(e) => { if (!e) return; setSuburbValue(e.structured_formatting.main_text); }}
                  onCancelSelectedSuburbCleared={() => setSelectedSuburbCleared(false)}
                  search={search} reset={reset}
                />
              </Collapse>
            </Paper>

            {hasError
              ? <ErrorAlert
                  isDarkMode={isDarkMode}
                  message={i18n.t('alert.error.data.fetching.description')}
                />
              : <InfoPanel
                  day={day}
                  loaded={loaded}
                  data={data}
                  defaultView={defaultView}
                  currentLocation={currentLocation}
                  showSurrounding={showSurrounding}
                  isDarkMode={isDarkMode}
                  isSmallScreen={isSmallScreen}
                  isSearchBarOpen={isSearchBarOpen}
                  locationTarget={locationTarget}
                  currentLocaleInfo={currentLocaleInfo}
                  selectedFuelRegion={selectedFuelRegion}
                  selectedSuburb={suburb}
                  selectedSuburbLatLng={suburbLatLng}
                  onTabChanged={(e, value) => { setLoaded(false); setDay(value); GtagEventService.logCustomEvent(GTAG_EVENTS.tab, { day: value }) }}
                  onSuburbCellClick={onSuburbCellClick}
                />
            }
          </>
        }
      </Box>

      <Paper sx={{ bgcolor: isDarkMode ? 'background.footer.dark' : 'background.footer.light' }}>
        <Footer />
      </Paper>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={showNewVersionNotification}
      >
        <SnackbarContent
          message={i18n.t('site.version.update.text')}
          action={(<Button variant='contained' color='secondary' size='small' onClick={onSnackbarClose}>{i18n.t('site.version.refresh.button')}</Button>)}
        />
      </Snackbar>
    </ThemeProvider>
  );
}

export default App;
