import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import PlaceIcon from '@mui/icons-material/Place';
import { appSettings } from '../../constants/setting-defaults';

import i18next from '../../i18n';

export default function CurrentLocation(props) {
    return (
        <Box sx={{mt: 1, mb: 3, display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            <Typography variant='subtitle2' component='div' sx={{display: 'flex', alignItems: 'center'}}>
                <PlaceIcon sx={{fontSize: '1rem', ml: 0.5}} />
                <Box component='span' sx={{ml: 0.25}}>
                    {i18next.t('subtitle.location.current')}: 
                </Box> 
            </Typography>

            {props.currentLocaleInfo && props.currentLocaleInfo.locality ? (
                props.locationTarget === appSettings.locationTarget.suburb ? (
                    <Button color='secondary' value={props.currentLocaleInfo?.locality} onClick={props.onSuburbCellClick} sx={{fontWeight: 'bold'}}>
                        {props.currentLocaleInfo?.locality}
                    </Button>) : (
                        <Typography variant='span' color='text.secondary' sx={{ ml: 1, textTransform: 'uppercase', fontWeight: 'bold'}}>{props.currentLocaleInfo?.locality}</Typography>
                    )
            ) : (
                <Typography variant='button' color='text.secondary' sx={{ ml: 1 }}>{i18next.t('subtitle.location.unknown')}</Typography>
            )}
        </Box>
    );
}