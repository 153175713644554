import { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Link from '@mui/material/Link';
import DirectionsIcon from '@mui/icons-material/Directions';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';

import { gMapsDirUrl } from '../services/url-service';
import GtagEventService from '../services/gtag-service';
import { GTAG_EVENTS } from '../constants/gtag-events';

import i18next from '../i18n';

export default function RowMenu(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        GtagEventService.logCustomEvent(GTAG_EVENTS.table.row.moreActions)
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box>
            <IconButton
                aria-label='show more options'
                aria-haspopup="true"
                aria-expanded={open ? 'true' : 'false'}
                onClick={handleClick}
                sx={{padding: {xs: 0}}}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={() => { handleClose(); GtagEventService.logCustomEvent(GTAG_EVENTS.directions, {url: gMapsDirUrl(props.station)}); } }>
                    <DirectionsIcon sx={{mr: 1}} />
                    <Link href={gMapsDirUrl(props.station)} underline="none" color="inherit" target='_blank' rel="noopener">
                        {i18next.t('site.link.directions')}
                    </Link>
                </MenuItem>
                <MenuItem onClick={() => { props.handleExpandRow(); handleClose(); } }>
                    <UnfoldMoreIcon sx={{mr: 1}} /> 
                    <Typography>{i18next.t('table.details.show')}</Typography>
                </MenuItem>
                <MenuItem onClick={() => { props.handleCollapseRow(); handleClose(); } }>
                    <UnfoldLessIcon sx={{mr: 1}} />
                    <Typography>{i18next.t('table.details.hide')}</Typography>
                </MenuItem>
            </Menu>
        </Box>
    );
}