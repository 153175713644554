export function formatUrl(baseUrl, query) {
    const queryString = new URLSearchParams(query).toString();
    return queryString ? `${baseUrl}?${queryString}` : baseUrl;
}

/// fuelRegion param has a format of [regionType].[regionValue], e.g. stateRegion.3 or town.8 or metro.25
export function createApiQuery(fuelType, fuelBrand, showSurrounding, fuelRegion, suburb, locationTarget) {
    const fuelRegionParts = fuelRegion.split('.');
    const apiQuery = {
        product: fuelType, 
        brand: fuelBrand, 
        surrounding: showSurrounding ? 'yes' : 'no',
        region: locationTarget !== 'suburb' && fuelRegionParts && (fuelRegionParts[0] === 'town' || (fuelRegionParts[0] === 'metro' && fuelRegionParts[1] !== '98')) ? fuelRegionParts[1] : '',
        stateRegion: locationTarget !== 'suburb' && fuelRegionParts && (fuelRegionParts[0] === 'stateRegion' || (fuelRegionParts[0] === 'metro' && fuelRegionParts[1] === '98')) ? fuelRegionParts[1] : '',
        suburb: locationTarget === 'suburb' && suburb ? suburb : ''
    };

    // remove any property with empty value
    for (const key in apiQuery) {
        if(apiQuery[key] === '')
            delete apiQuery[key];
    }

    return apiQuery;
}

export function gMapsDirUrl(searchTerm) {
    return `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(searchTerm)}`;
}