import { useState, useMemo, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { debounce } from '@mui/material/utils';

import i18next from '../i18n';

const autocompleteService = { current: null };

export default function SuburbLookup(props) {
    const [value, setValue] = useState(props.selectedSuburb || null);
    const [inputValue, setInputValue] = useState(props.selectedSuburb || '');
    const [options, setOptions] = useState([]);

    const fetch = useMemo(
        () =>
            debounce((request, callback) => {
                autocompleteService.current.getPlacePredictions(request, callback);
            }, 400),
        [],
    );

    useEffect(() => {
        let active = true;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current =
                new window.google.maps.places.AutocompleteService();
        }

        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }
        
        if (props.selectedSuburbCleared) {
            setInputValue('');
            setValue(null);
        }

        const request = {
            input: inputValue,
            componentRestrictions: { country: 'au' },
            locationRestriction: {
                south: -35.065835,
                west: 113.029373,
                north: -13.867939,
                east: 128.985519
            },
            types: ['(cities)']
        }

        fetch(request, (results) => {
            if (active) {
                let newOptions = [];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch, props.selectedSuburbCleared, props.selectedSuburb]);

    return (
        <Autocomplete
            id="inputSuburbLookup"
            size="small"
            getOptionLabel={(option) =>
                typeof option === 'string' ? option : option.structured_formatting.main_text
            }
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            noOptionsText={i18next.t('site.search.suburb.noOptions')}
            onChange={(e, newValue) => {
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);
                props.onSuburbSelected(newValue);
            }}
            onInputChange={(e, newInputValue) => {
                props.onCancelSelectedSuburbCleared();
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField {...params} label={i18next.t('site.search.suburb')} fullWidth />
            )}
            renderOption={(renderProps, option, state) => {
                let { key, ...propsWithoutKey} =  renderProps;
                return (
                    <li key={state.index} {...propsWithoutKey}>
                        <Grid container alignItems="center">
                            <Grid item sx={{ wordWrap: 'break-word' }}>
                                <Typography variant="body1" color="text.primary" sx={{fontWeight: 'bold'}}>
                                    {option.structured_formatting.main_text}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {option.structured_formatting.secondary_text}
                                </Typography>
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
        />
    );
}
