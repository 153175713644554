import { forwardRef } from 'react';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SuburbLookup from './SuburbLookup';

import { getBrandMarker } from '../services/gmap-service';

import i18next from '../i18n';

const SearchBar = forwardRef((props, ref) => {

    return (
        <Stack direction={{ sm: 'column', md: 'row' }} ref={ref} sx={{my: 2, md: {alignItems: 'center'}, justifyContent: 'center' }}>
            <FormControl size='small' sx={{ m: {xs: 0.5 }, minWidth: {sm: '10%'} }}>
                <InputLabel id="fuel-type">{i18next.t('site.search.fuelType')}</InputLabel>
                <Select
                    labelId="fuel-type"
                    name="fuel-types-select"
                    value={props.selectedFuelType}
                    label={i18next.t('site.search.fuelType')}
                    onChange={props.onFuelTypeSelected}
                >
                    {props.fuelTypes.map(f => 
                        <MenuItem value={f.value} key={f.key}>{i18next.t(f.label)}</MenuItem>
                    )}
                </Select>
            </FormControl>

            <FormControl size='small' sx={{ m: {xs: 0.5 }, minWidth: {sm: '15%'} }}>
                <InputLabel id="fuel-brand">{i18next.t('site.search.fuelBrand')}</InputLabel>
                <Select
                    labelId="fuel-brand"
                    name="fuel-brands-select"
                    value={props.selectedFuelBrand}
                    label={i18next.t('site.search.fuelBrand')}
                    onChange={props.onFuelBrandSelected}
                >
                    {props.fuelBrands.map(f => 
                        // <MenuItem value={f.value} key={f.key}>{f.label}</MenuItem>
                        <MenuItem value={f.value}>
                            <ListItemIcon>
                                <img alt='brand-icon' src={getBrandMarker(f.label)} />
                            </ListItemIcon>
                            <ListItemText sx={{pl: 3}}>{f.label}</ListItemText>
                        </MenuItem>
                    )}
                </Select>
            </FormControl>

            {props.locationTarget === 'suburb' ? (
                <>
                    <FormControl sx={{ m: {xs: 0.5}, minWidth: {sm: '15%'} }}>
                        <SuburbLookup 
                            selectedSuburb={props.selectedSuburb}
                            selectedSuburbCleared={props.selectedSuburbCleared}
                            onSuburbSelected={props.onSuburbSelected}
                            onCancelSelectedSuburbCleared={props.onCancelSelectedSuburbCleared}
                        />
                    </FormControl>
                    <FormControlLabel control={<Switch checked={props.showSurrounding}
                        onChange={props.onSurroundingChanged} />} 
                        label={i18next.t('site.search.surrounding')} 
                        name='surrounding'
                        sx={{ m: {xs: 0.25, sm: 0.5 } }} 
                    />
                </>
            ) : props.locationTarget === 'metro' 
                ? (
                    <FormControl size='small' sx={{ m: {xs: 0.5 }, minWidth: {sm: '15%'} }}>
                        <InputLabel id="fuel-metro">{i18next.t('site.search.metro')}</InputLabel>
                        <Select
                            labelId="fuel-metro"
                            name="fuel-metros-select"
                            value={props.selectedFuelRegion}
                            label={i18next.t('site.search.metro')}
                            onChange={props.onFuelRegionSelected}
                        >
                            {props.fuelStateRegions.slice(-1).map(f => 
                                <MenuItem itemType={f.type} value={`${f.type}.${f.value}`} key={f.key}>{i18next.t(f.label)}</MenuItem>
                            )}
                            
                            {props.fuelRegions.slice(0, 3).map(f => 
                                <MenuItem itemType={f.type} value={`${f.type}.${f.value}`} key={f.key}>{i18next.t(f.label)}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    )
                : (
                    <FormControl size='small' sx={{ m: {xs: 0.5 }, minWidth: {sm: '15%'} }}>
                        <InputLabel id="fuel-region-town">{i18next.t('site.search.region')}</InputLabel>
                        <Select
                            labelId="fuel-region-town"
                            name="fuel-regions-select"
                            value={props.selectedFuelRegion}
                            label={i18next.t('site.search.region')}
                            onChange={props.onFuelRegionSelected}
                        >
                            <ListSubheader>{i18next.t('site.search.category.region')}</ListSubheader>
                            {props.fuelStateRegions.slice(0, -1).map(f => 
                                <MenuItem itemType={f.type} value={`${f.type}.${f.value}`} key={f.key}>{f.label}</MenuItem>
                            )}
                            
                            <ListSubheader>{i18next.t('site.search.category.town')}</ListSubheader>
                            {props.fuelRegions.slice(3).map(f => 
                                <MenuItem itemType={f.type} value={`${f.type}.${f.value}`} key={f.key}>{f.label}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                )
            }
            
            <Button aria-label="search" 
                variant='contained' 
                color='success' 
                size='small'
                disableElevation
                startIcon={<SearchIcon />} 
                onClick={props.search} 
                sx={{ m: {xs: 0.5 } }}
            >
                {i18next.t('site.search')}
            </Button>

            <Button aria-label="reset" 
                variant='contained' 
                color='warning' 
                startIcon={<RestartAltIcon />} 
                size='small'
                disableElevation
                onClick={props.reset} 
                sx={{ 
                    m: {xs: 0.5 }
                }}
            >
                {i18next.t('site.reset')}
            </Button>
        </Stack>
    );
});

export default SearchBar;