import { useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import CircularProgress from '@mui/material/CircularProgress';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import SettingsIcon from '@mui/icons-material/Settings';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import CampaignIcon from '@mui/icons-material/Campaign';
import CloseIcon from '@mui/icons-material/Close';
import AssessmentIcon from '@mui/icons-material/Assessment';

import SettingsPanel from './SettingsPanel';
import { GTAG_EVENTS } from '../constants/gtag-events';
import GtagEventService from '../services/gtag-service';
import { DATA_CONST } from '../constants/data-constants';

import i18next from '../i18n';

const alertsApiUrl = process.env.NODE_ENV === 'production' ? process.env['REACT_APP_FPWA_ALERTS_API'] : DATA_CONST.localApis.alerts;

export default function NavBar(props) {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [alertsLoaded, setAlertsLoaded] = useState(false);
    const [alerts, setAlerts] = useState([{}]);
    const routerNav = useNavigate();
    const routerLocation = useLocation();

    const onDrawerOpen = (e) => {
        setIsDrawerOpen(true);
        GtagEventService.logCustomEvent(GTAG_EVENTS.settings.panelState, { state: 'open' });
    };

    const onDrawerClose = (e) => {
        setIsDrawerOpen(false);
        GtagEventService.logCustomEvent(GTAG_EVENTS.settings.panelState, { state: 'close' });
    };

    const onLogoClick = () => {
        window.location.href = window.location.origin;
    };

    const onChartClick = () => {
        routerNav('/chart');
    };

    const onPopupOpen = async () => {
        setIsPopupOpen(true);
        setAlertsLoaded(false);

        const data = await ((await fetch(alertsApiUrl)).json());
        setAlerts(data);
        setAlertsLoaded(true);
        GtagEventService.logCustomEvent(GTAG_EVENTS.notifications.popupState, { state: 'open' });
    }

    const onPopupClose = () => {
        setIsPopupOpen(false);
        GtagEventService.logCustomEvent(GTAG_EVENTS.notifications.popupState, { state: 'close' });
    }

    return (
        <AppBar position={props.position}>
            <Toolbar sx={{px: {xs: 0, sm: 0, lg: 2}}}>
                <IconButton title='Logo'
                    onClick={onLogoClick}
                    sx={{ color: '#fff' }}
                >
                    <LocalGasStationIcon />
                </IconButton>
                <Typography variant="h1" noWrap onClick={onLogoClick} sx={{ flexGrow: 1, cursor: 'pointer', fontSize: { xs: '1rem', sm: '1.25rem', md: '1.5rem' } }}>
                    {i18next.t('site.title')}
                </Typography>

                {props.isSmallScreen && routerLocation.pathname !== '/chart' && (
                    <IconButton title={i18next.t('site.search')}
                        onClick={props.onSearchBarOpen}
                        sx={{ color: '#fff' }}
                    >
                        <ManageSearchIcon />
                    </IconButton>
                )}
                <IconButton
                    title={i18next.t('chart.title')}
                    onClick={onChartClick}
                    sx={{ color: '#fff' }}
                >
                    <AssessmentIcon />
                </IconButton>
                <IconButton
                    title={i18next.t('notif.title')}
                    onClick={onPopupOpen}
                    sx={{ color: '#fff' }}
                >
                    <CampaignIcon />
                </IconButton>
                <IconButton
                    title={i18next.t('settings.title')}
                    onClick={onDrawerOpen}
                    sx={{ color: '#fff' }}
                >
                    <SettingsIcon />
                </IconButton>
                <SettingsPanel
                    anchor='right'
                    isDrawerOpen={isDrawerOpen}
                    locationTarget={props.locationTarget}
                    defaultView={props.defaultView}
                    backgroundMode={props.isDarkMode ? 'dark' : 'light'}
                    language={props.language}
                    currentLocaleInfo={props.currentLocaleInfo}
                    fuelTypes={props.fuelTypes}
                    preferredFuelType={props.preferredFuelType}
                    onDrawerOpen={onDrawerOpen}
                    onDrawerClose={onDrawerClose}
                    onLocationTargetChange={props.applyLocationTarget}
                    onDefaultViewChange={props.applyDefaultView}
                    onBackgroundModeChange={props.applyBackgroundMode}
                    onLanguageChange={props.onLanguageChange}
                    onFuelTypeChange={props.onFuelTypeChange}
                />
                <Dialog
                    onClose={onPopupClose}
                    open={isPopupOpen}
                >
                    <DialogTitle sx={{ m: 0, p: 2 }}>
                        {i18next.t('notif.title')}
                    </DialogTitle>
                    <IconButton
                        onClick={onPopupClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent dividers>
                        {!alertsLoaded ? (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <CircularProgress sx={{ m: 2 }} /> {i18next.t('notif.loading')}
                            </Box>
                        ) : (
                            alerts.map(a => (
                                <Accordion key={a.id}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        {a.title}
                                    </AccordionSummary>
                                    <AccordionDetails dangerouslySetInnerHTML={{ __html: a.details }} />
                                </Accordion>
                            ))
                        )}
                    </DialogContent>
                </Dialog>
            </Toolbar>
        </AppBar>
    );
}