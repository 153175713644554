import { appSettings } from '../constants/setting-defaults';

export const NO_CACHING = -1;

export default class CacheService {
    static cacheItem(key, item, ttl) {
        const now = new Date();
        const cacheObject = {
            item: item,
            expiry: ttl > 0 ? now.getTime() + ttl : NO_CACHING
        }
        localStorage.setItem(key, JSON.stringify(cacheObject));
    }

    static getCachedItem(key) {
        const cachedValue = localStorage.getItem(key);
        if (!cachedValue)
            return null;

        const cachedObject = JSON.parse(cachedValue);
        const now = new Date();

        if (cachedObject.expiry !== NO_CACHING && now.getTime() > cachedObject.expiry) {
            localStorage.removeItem(key);
            return null;
        }

        return cachedObject.item;
    }
}

export function initAppSettings() {
    return CacheService.getCachedItem(appSettings.cacheKey.settings);
}