import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';

import SkeletonTable from './SkeletonTable';
import InfoTable from './InfoTable';
import MapView from './MapView';
import InfoCard from './InfoCard';
import CurrentLocation from '../components/shared/CurrentLocation';

import { DATA_CONST } from '../constants/data-constants';
import { appSettings } from '../constants/setting-defaults';
import { getDataLabel } from '../services/data-service';

import { Trans } from 'react-i18next';
import i18next from '../i18n';

function getTabLabel(text, index) {
    const dateNow = new Date();
    let dateString = dateNow.toLocaleDateString();
    if (index === 1) {
        const dateTmrw = (new Date()).setDate(dateNow.getDate() + 1);
        dateString = new Date(dateTmrw).toLocaleDateString();
    }
    else if (index === 2) {
        const dateYstd = (new Date()).setDate(dateNow.getDate() - 1);
        dateString = new Date(dateYstd).toLocaleDateString();
    }
    return `${i18next.t(text, { i18nKey: text })} ${dateString}`;
}

function getTitle() {
    return i18next.t('main.title.general');
}

function getSubtitle(props) {
    if (props.locationTarget === appSettings.locationTarget.region)
        return `${i18next.t('site.search.region')}`;

    if (props.selectedSuburb) {
        return `${props.selectedSuburb} ${!!props.showSurrounding ? i18next.t('and') + ' ' + i18next.t('site.search.surrounding') : ''}`;
    }

    if (props.selectedFuelRegion)
        return `${i18next.t(getDataLabel('fuelRegion', props.selectedFuelRegion))}`;
        
    return `${i18next.t('Metro All')}`; 
}

function AlertView(props) {
    return (
        <Trans>
            <Box sx={{maxWidth: {lg: '60vw'}, mx: 'auto'}}>
                <Alert severity='warning' sx={{ paddingBottom: 4, m: {xs: 0.5, sm: 0.5, md: 1} }}>
                    <AlertTitle>{i18next.t('alert.warning.noRecords.title')}</AlertTitle>
                    {i18next.t('alert.warning.noRecords.description.pt1')}
                    {props.day === DATA_CONST.dayOptions[1] ?
                        i18next.t('alert.warning.noRecords.description.pt2')
                        : null
                    }
                </Alert>
            </Box>
        </Trans>
    );
}

function TableView(props) {
    if (!props.loaded)
        return (
            <Box sx={{px: {xs: 0, sm: 0.5, md: 1}, maxWidth: {lg: '60vw'}, mx: 'auto'}}>
                <SkeletonTable isDarkMode={props.isDarkMode} rowRepeats={5} colRepeats={5} />
            </Box>
        );

    if (props.data && props.data.length > 0) {
        return (
            <Box sx={{px: {xs: 0, sm: 0.5, md: 1}, maxWidth: {lg: '60vw'}, mx: 'auto'}}>
                <InfoTable data={props.data} 
                    isDarkMode={props.isDarkMode}
                    loaded={props.loaded} 
                    locationTarget={props.locationTarget}
                    onSuburbCellClick={props.onSuburbCellClick} 
                />
                <CurrentLocation currentLocaleInfo={props.currentLocaleInfo}
                    locationTarget={props.locationTarget}
                    onSuburbCellClick={props.onSuburbCellClick}
                />
            </Box>
        );
    }

    return AlertView(props);
}

function PanelItem(props) {
    if (props.defaultView === appSettings.defaultView.table) {
        return (TableView(props));
    }

    return !props.loaded || (props.data && props.data.length > 0) ? MapView(props) : AlertView(props);
}

function InfoBox(props) {
    if (props.data && props.data.length > 0) {
        return (
            <Alert variant='standard' severity='info' sx={{my: {xs: 0.25, sm: 0.5}, mx: {xs: 0, sm: 1}, px: {xs: 0.5, sm: 1}}}>
                <>
                    <Box>
                        {props.loaded ? 
                            (
                                <Typography variant="subtitle2" component='p'>{i18next.t('subtitle.cpl')}</Typography>
                            ) :
                            (<Skeleton animation="wave" variant="rounded" sx={{width: {xs: '80vw', sm: '60vw', md: '30vw'}}} />)
                        }
                    </Box>
                    {props.defaultView === appSettings.defaultView.table && (
                        <>
                            <Box>
                                {props.loaded ? 
                                    (
                                        <Typography variant="subtitle2" component='p'>{i18next.t('subtitle.station.click')}</Typography>
                                    ) :
                                    (<Skeleton animation="wave" variant="rounded" sx={{width: {xs: '70vw', sm: '50vw', md: '25vw'}, my: 1}} />)
                                }
                            </Box>
                            {props.locationTarget === appSettings.locationTarget.suburb &&
                                <Box>
                                    {props.loaded ? 
                                        (
                                            <Typography variant="subtitle2" component='p'>{i18next.t('subtitle.suburb.click')}</Typography>
                                        ) :
                                        (<Skeleton animation="wave" variant="rounded" sx={{width: {xs: '60vw', sm: '40vw', md: '20vw'}}}/>)
                                    }
                                </Box>
                            }
                        </>
                    )}
                </>
            </Alert>
        );
    }
    return <></>;
}

export default function InfoPanel(props) {

    return (
        <Box sx={{ width: '100%' }} component={'main'}>
            <Box sx={{maxWidth: {lg: '60vw'}, mx: 'auto', my: {xs: 1, sm: 1.25, md: 2, lg: 4}, textAlign: 'center'}}>
                <Typography variant='h2' sx={{ fontSize: { xs: '1.125rem', sm: '1.25rem', md: '1.25rem' }}} gutterBottom>
                    {getTitle()}
                </Typography>
                <Typography variant="h3" color='text.secondary' sx={{ fontSize: { xs: '0.65rem', sm: '0.8rem', md: '0.9rem' } }} gutterBottom>
                    ({getSubtitle(props)})
                </Typography>
            </Box>
            {/* <Box sx={{maxWidth: {lg: '60vw'}, mx: 'auto'}}>
                <InfoBox data={props.data} 
                    loaded={props.loaded} 
                    defaultView={props.defaultView}
                    locationTarget={props.locationTarget} 
                />
            </Box> */}
            <TabContext value={props.day}>
                <Box sx={{
                    borderBottom: 1,
                    borderColor: "divider"
                }}>
                    <TabList centered
                        onChange={props.onTabChanged}
                    >
                        {DATA_CONST.dayOptions.map((t, i) =>
                            <Tab wrapped label={getTabLabel(t, i)} value={t} key={t} sx={{ maxWidth: '8rem', fontSize: '0.875rem', fontWeight: 'bold' }}></Tab>
                        )}
                    </TabList>
                </Box>
                {DATA_CONST.dayOptions.map((t, i) =>
                    <TabPanel value={DATA_CONST.dayOptions[i]} key={i} sx={{ padding: { xs: 0, sm: 0, md: 0.5 }, mx: { md: 2} }}>
                        <Box sx={{maxWidth: {lg: '60vw'}, mx: 'auto'}}>
                            <InfoCard data={props.data} 
                                loaded={props.loaded} 
                                day={props.day} 
                                isDarkMode={props.isDarkMode} 
                                defaultView={props.defaultView}
                                locationTarget={props.locationTarget} 
                            />
                        </Box>
                        <PanelItem data={props.data}
                            loaded={props.loaded}
                            day={props.day}
                            isDarkMode={props.isDarkMode}
                            isSmallScreen={props.isSmallScreen}
                            defaultView={props.defaultView}
                            currentLocation={props.currentLocation}
                            currentLocaleInfo={props.currentLocaleInfo}
                            locationTarget={props.locationTarget}
                            selectedFuelRegion={props.selectedFuelRegion}
                            selectedSuburb={props.selectedSuburb}
                            selectedSuburbLatLng={props.selectedSuburbLatLng}
                            onSuburbCellClick={props.onSuburbCellClick}
                        />
                    </TabPanel>
                )}
            </TabContext>
        </Box>
    );
}
