import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';

import i18next from '../i18n';
import { getDataLabel } from '../services/data-service';

export default function SelectedFiltersPanel(props) {
    return props.isSmallScreen && (
        <Box>
            <Stack direction="row" 
                flexWrap='nowrap'
                justifyContent='flex-start'
                spacing={1}
                sx={{p: 1, overflowX: 'scroll', scrollbarWidth: 'none'}}
            >
                { props.selectedFuelType && (
                    <Chip label={i18next.t(getDataLabel('fuelType', props.selectedFuelType))} 
                        onClick={props.onToggleSearchBar}
                        onDelete={() => props.onClearFilter('fuelType')} 
                    />
                )}    
                { props.selectedFuelBrand && (
                    <Chip label={getDataLabel('fuelBrand', props.selectedFuelBrand)} 
                        onClick={props.onToggleSearchBar}
                        onDelete={() => props.onClearFilter('fuelBrand')} 
                    />
                )}
                { props.selectedSuburb && (
                    <Chip label={props.selectedSuburb} 
                        onClick={props.onToggleSearchBar} 
                        onDelete={() => props.onClearFilter('suburb')} 
                    />
                )}
                { props.selectedFuelRegion && (
                    <Chip label={getDataLabel('fuelRegion', props.selectedFuelRegion)} 
                        onClick={props.onToggleSearchBar}
                        onDelete={() => props.onClearFilter('fuelRegion')} 
                    />
                )}
                { props.showSurrounding && (
                    <Chip label={i18next.t('site.search.surrounding')} 
                        onClick={props.onToggleSearchBar} 
                        onDelete={() => props.onClearFilter('surrounding')} 
                    />
                )}
            </Stack>
        </Box>
    );
}