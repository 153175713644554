import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Skeleton from '@mui/material/Skeleton';
import SharedTableHead from './shared/TableHead';

export default function SkeletonTable(props) {
    return (
        <Table>
            <SharedTableHead isDarkMode={props.isDarkMode} />
            <TableBody sx={{bgcolor: props.isDarkMode ? 'background.infoTableBody.dark' : 'background.infoTableBody.light'}}>
                {
                    [...Array(props.rowRepeats)].map((x, i) => (
                        <TableRow key={i} sx={{ 
                            '& > td': { borderBottom: 'none'}, 
                            '& > td:last-child': { pl: {xs: 0} },
                            '& > td:first-of-type': { pl: 2}
                            }}
                        >
                            {[...Array(props.colRepeats)].map((x, j) =>
                                <TableCell key={j} sx={{borderBottom:'none', pl: 0.5, py: 1}}>
                                    <Skeleton animation="wave" variant="rounded" key={j} height='1.5rem' />
                                </TableCell>
                            )}
                        </TableRow>
                    ))
                }
            </TableBody>
        </Table>
    );
}