export const getLocality = (r) => {
    var locality = '';
    var state = 'WA';
    if (!r || !r.length)
        return locality;

    const localities = r.filter(g => g.types.some(t => t === 'locality'));
    const states = r.filter(g => g.types.some(t => t === 'administrative_area_level_1'));

    if (localities.length > 0)
        locality = localities[0].address_components[0].long_name;
    if (states.length > 0)
        state = states[0].address_components[0].short_name;

    return {
        locality: locality,
        state: state
    };
};


export const onGeolocationError = (err) => { console.error(err.code + ' - ' + err.message); };

export const initGeolocation = (onGeolocationSuccess) => {
    if (!navigator.geolocation) {
        const msg = "Geolocation API is not supported on your browser...";
        console.warn(msg);
        alert(msg);
  
        return;
    }
  
    navigator.geolocation.getCurrentPosition((pos) => onGeolocationSuccess(pos), (err) => onGeolocationError(err));
}


