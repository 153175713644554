import { useState, useMemo } from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Link from '@mui/material/Link';
import DirectionsIcon from '@mui/icons-material/Directions';
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
//import { MarkerClusterer } from '@react-google-maps/api'

import CurrentLocation from './shared/CurrentLocation';
import { DATA_CONST } from '../constants/data-constants';
import { gMapsDirUrl } from '../services/url-service';
import { getMinPrice } from '../services/data-service';
import GtagEventService from '../services/gtag-service';
import { 
    setMarkerLabel, getZoomLevel, 
    getMapCenter, getBrandMarker
} from '../services/gmap-service';

import i18next from '../i18n';
import { GTAG_EVENTS } from '../constants/gtag-events';

const containerStyle = DATA_CONST.mapContainerStyle;
//const mapOptions = DATA_CONST.defaultMapOptions;

export default function MapView(props) {
    const isLoaded = window.google.maps !== undefined && props.loaded;
    const center = getMapCenter(props.locationTarget, props.selectedFuelRegion, props.currentLocation, props.selectedSuburbLatLng);
    const zoom = getZoomLevel(props.locationTarget, props.selectedFuelRegion, props.selectedSuburb, props.isSmallScreen);
    const lowestPrice = getMinPrice(props.data);
    const dataPoints = useMemo(() => {
        return props.data.map((d) => ({
            key: `${d.latitude}_${d.longitude}`,
            position: { lat: d.latitude, lng: d.longitude },
            label: setMarkerLabel(lowestPrice, d),
            icon: {
                url: `${getBrandMarker(d.brand)}`,
                scaledSize: new window.google.maps.Size(DATA_CONST.defaultMapMarkerIcon.width, DATA_CONST.defaultMapMarkerIcon.height)
            },
            id: d.station,
            address: d.address,
            suburb: d.suburb,
            brand: d.brand.toLowerCase(),
            price: d.price,
            phone: d.phone
        }))
    }, [props.data, lowestPrice]);

    const [selectedMarker, setSelectedMarker] = useState(null);

    const onMarkerClick = (m) => {
        setSelectedMarker(m);
        GtagEventService.logCustomEvent(GTAG_EVENTS.map.marker.click);
    };

    const onInfoWindowClose = () => {
        setSelectedMarker(null);
    };

    const cardHeaderText = (lowestPrice) => {
        const thumbUpLabel = Number(lowestPrice) === Number(selectedMarker.price)
            ? i18next.t('map.label.cheapest')
            : '';
        return `${thumbUpLabel} ${i18next.t(props.day)}: ${selectedMarker.label.text}${i18next.t('map.label.cpl')}`
    };

    const InfoPopup = (props) => {
        return (
            <InfoWindow
                position={selectedMarker.position}
                options={{
                    alignBottom: true,
                    pixelOffset: new window.google.maps.Size(DATA_CONST.defaultMapInfoPopup.offsetInPixel.width, DATA_CONST.defaultMapInfoPopup.offsetInPixel.height)
                }}
                onCloseClick={onInfoWindowClose}
            >
                <Card sx={{p:0, m:0, bgcolor:'background.mapInfoPopup.light', borderRadius:0, boxShadow:'none', maxWidth:'16rem'}}>
                    <Box sx={{ display: 'flex', alignItems: 'center', color: 'color.mapInfoPopup.text' }}>
                        <Box sx={{flex: '1 1 auto', alignSelf: 'flex-start', width: '50%', pr: 1}}>
                            <img src={`${getBrandMarker(selectedMarker.brand)}`} 
                                alt={selectedMarker.brand}
                            /> 
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', ml: 'auto' }}>
                            <CardContent sx={{ flex: '1 1 auto', m: 0, p: 0 }}>
                                <Typography color='color.mapInfoPopup.highlightText'>
                                    <strong>{cardHeaderText(lowestPrice)}</strong>
                                </Typography>
                                <Typography component="div" variant="body2">
                                    <strong>{selectedMarker.id}</strong>
                                </Typography>
                                <Typography variant="subtitle2" component="div">
                                    {selectedMarker.address}, {selectedMarker.suburb}
                                </Typography>
                                {selectedMarker.phone !== 'N/A' && (
                                    <Link href={`tel:${selectedMarker.phone}`} 
                                        color='color.mapInfoPopup.link'
                                        underline='none'
                                    >
                                        <Typography component='span'>{selectedMarker.phone}</Typography>
                                    </Link>
                                )}
                            </CardContent>
                        </Box>
                    </Box>
                    <CardActions sx={{m: 0, p: 0, justifyContent: 'center' }}>
                        <Link href={gMapsDirUrl(selectedMarker.id)} 
                            color='color.mapInfoPopup.link'
                            target='_blank' rel="noopener" 
                            underline='none'
                            onClick={() => GtagEventService.logCustomEvent(GTAG_EVENTS.directions)} 
                            sx={{display: 'flex', alignContent:'center'}}
                        >
                            <DirectionsIcon color='inherit' /> <Typography component='span' sx={{ml: 1}}>{i18next.t('site.link.directions')}</Typography>
                        </Link>
                    </CardActions>
                </Card>
            </InfoWindow>
        );
    };

    return (
        <Box sx={{ width: '100%' }} component={'main'}>
            <>
                {!isLoaded && (
                    <Box sx={{ position: 'absolute', left: props.isSmallScreen ? '0' : '50%', bottom: 0, 
                        backgroundColor: 'background.mapLoadingPopup', zIndex: 9999, borderRadius: 2, 
                        mx: 1.5, opacity: 0.85
                    }}>
                        <Box sx={{display: 'flex', alignItems: 'center', p: 1}}>
                            <Box>
                                <CircularProgress disableShrink />
                            </Box>
                            <Box sx={{ml: 1}}>
                                {i18next.t('map.loading')}...
                            </Box>
                        </Box>
                    </Box>
                )}
                <GoogleMap
                    id='mapView'
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={zoom}
                    options={{
                        restriction: DATA_CONST.defaultMapBoundRestriction,
                        strictBounds: false,
                        styles: DATA_CONST.defaultMapFeatureStyles.hide
                    }}
                >
                    {dataPoints.map((d) => (
                        <Marker
                            clickable={true}
                            cursor='pointer'
                            key={d.key}
                            position={d.position}
                            label={d.label}
                            icon={d.icon}
                            options={{
                                optimized: true
                            }}
                            onClick={() => onMarkerClick(d)}
                        />
                    ))}
                    {/* <MarkerClusterer options={mapOptions}>
                        {(clusterer) =>
                            dataPoints.map((d) => (
                                <Marker
                                    clickable={true}
                                    cursor='pointer'
                                    key={d.key}
                                    position={d.position}
                                    label={d.label}
                                    icon={d.icon}
                                    clusterer={clusterer}
                                    onClick={() => onMarkerClick(d)}
                                />
                            ))
                        }
                    </MarkerClusterer> */}

                    {selectedMarker && <InfoPopup day={props.day} /> }
                </GoogleMap>
                <CurrentLocation currentLocaleInfo={props.currentLocaleInfo}
                    locationTarget={props.locationTarget}
                    onSuburbCellClick={props.onSuburbCellClick}
                />
            </>
        </Box>
    );
}