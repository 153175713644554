import { appSettings } from '../constants/setting-defaults';
import { DATA_CONST } from '../constants/data-constants';
import { fuelRegionsData, fuelBrandsData } from './data-service';

const setMarkerLabel = (lowestPrice, d) => {
    return {
        text: `${d.price}${Number(lowestPrice) === Number(d.price) ? '🤩' : ''}`, 
        color: Number(lowestPrice) === Number(d.price) ? 'white' : 'black', 
        className: Number(lowestPrice) === Number(d.price) ? 'marker-label highlight' : 'marker-label'
    };
}; 

const getZoomLevel = (locationTarget, selectedFuelRegion, selectedSuburb, isSmallScreen) => {
    if (locationTarget === appSettings.locationTarget.metro)
        return 12;

    if (locationTarget === appSettings.locationTarget.region) {
        const fuelRegionParts = selectedFuelRegion.split('.');
        if (fuelRegionParts[0] === 'stateRegion')
            return 7;
            
        return fuelRegionParts[0] === 'town' && fuelRegionParts[1] !== '28' ? 12 : 9;
    }

    return selectedSuburb && selectedSuburb !== '' && !isSmallScreen ? 14 : 13;
};

/// fuelRegion param has a format of [regionType].[regionValue], e.g. stateRegion.3 or town.8 or metro.25
const getMapCenter = (locationTarget, selectedFuelRegion, currentLocation, selectedSuburbLatLng) => {
    if (selectedFuelRegion) {
        if (locationTarget === appSettings.locationTarget.metro || locationTarget === appSettings.locationTarget.region) {
            const fuelRegionParts = selectedFuelRegion.split('.');
            return fuelRegionsData().find(d => d.type === fuelRegionParts[0] && d.value === fuelRegionParts[1])?.mapCenter || DATA_CONST.defaultMapCenter;
        }
    }

    if (!selectedSuburbLatLng) {
        return currentLocation ? currentLocation : DATA_CONST.defaultMapCenter;
    }

    return selectedSuburbLatLng;
};

const getBrandMarker = (brand) => {
    if (!brand)
        return DATA_CONST.defaultMapMarkerIcon.path;

    const icon = fuelBrandsData().find(d => d.label.toLowerCase() === brand.toLowerCase() || d.label.toLocaleLowerCase().indexOf(brand.toLowerCase()) > -1)?.icon;
    return icon ? `images/${icon}` : DATA_CONST.defaultMapMarkerIcon.path;
};

export { setMarkerLabel, getZoomLevel, getMapCenter, getBrandMarker };