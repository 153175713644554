import { useRouteError } from "react-router-dom";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';

export default function ErrorView() {
    const error = useRouteError();
    console.error(error);

    return (
        <Box className='error-view'>
            <SentimentDissatisfiedIcon  />
            <Typography variant='h5' sx={{m: 2}}>
                <strong>{error.status} {error.statusText || error.message}</strong>
            </Typography>

            <Typography variant='h5'>Oops!</Typography>
            {error.status === 404 
                ? (<Typography variant='h6'>We can't seem to find the page you're looking for...</Typography>) 
                : (<Typography variant='h6'>An unexpected error has occurred...</Typography>)
            }

            <Button color="secondary" variant='contained' href='/' sx={{ m: 4 }}>Go to homepage</Button>
        </Box>
    );
}