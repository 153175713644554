export const appSettings = {
    cacheKey: { 
        settings: 'fpwa.app.settings',
        versions: 'fpwa.app.versions'
    },
    locationTarget: {
        suburb: 'suburb',
        metro: 'metro',
        region: 'region'
    },
    backgroundMode: {
        dark: 'dark',
        light: 'light'
    },
    defaultView: {
        table: 'table',
        map: 'map'
    },
    language: {
        en: 'en',
        zh: 'zh'
    },
    preferredFuelType: {
        value: '1'
    }
}