import { formatUrl } from './url-service';
import { DATA_CONST } from '../constants/data-constants';

function createDataModel(price, brand, station, address, suburb, siteFeatures, phone, latitude, longitude) {
    return { price, brand, station, address, suburb, siteFeatures, phone, latitude, longitude };
}

async function fetchWithRetry(url, options = {}, retries = 3, delay = 3000) {
    for (let attempt = 1; attempt <= retries; attempt++) {
        try {
            const response = await fetch(url, options);

            if (response.ok) {
                return response; // Successful response
            } else if (response.status === 503) {
                throw new Error('Service Unavailable (503)'); // Explicitly handle 503 status
            }

            throw new Error(`Request failed with status ${response.status}`);
        } catch (error) {
            if (attempt === retries) {
                throw error; // Exhausted all retries, rethrow the error
            }

            console.log(`Attempt ${attempt} failed. Retrying in ${delay/1000}s...`);
            await new Promise(resolve => setTimeout(resolve, delay)); // Delay before retrying
        }
    }
}

async function fetchData(baseUrl, query) {
    const url = formatUrl(baseUrl, query);
    return await (await fetchWithRetry(url)).json();
}

const fetchFuelPricesData = async (baseUrl, query) => {
    const data = await fetchData(baseUrl, query);
    return data.entries.map(d => { return createDataModel(d.price, d.brand, d.tradingName, d.address, d.location, d.siteFeatures, d.phone, d.latitude, d.longitude); });
};

const fetchAppVersion = async (url) => {
    return (await (await fetchWithRetry(url)).json()).appVersion;
};

/*
    refer to https://www.fuelwatch.wa.gov.au/tools/rss for details
**/
const fuelTypesData = () => {
    return [
        { key: 'ULP', value: '1', label: 'Unleaded' },
        { key: 'PULP', value: '2', label: 'Premium Unleaded' },
        { key: 'DSL', value: '4', label: 'Diesel' },
        { key: 'BDL', value: '11', label: 'Brand Diesel' },
        { key: '98R', value: '6', label: '98 RON' },
        { key: 'LPG', value: '5', label: 'LPG' },
        { key: 'E85', value: '10', label: 'E85' }
    ];
}

const fuelBrandsData = () => {
    return [
        { key: '7-eleven', value: '29', label: '7-Eleven', icon: '7-eleven.svg' },
        { key: 'ampol', value: '2', label: 'Ampol', icon: 'ampol.svg' },
        { key: 'astron', value: '41', label: 'Astron', icon: 'astron.svg' },
        { key: 'atlas', value: '34', label: 'Atlas', icon: 'atlas.svg' },
        { key: 'better_choice', value: '3', label: 'Better Choice', icon: 'better choice.svg' },
        { key: 'boc', value: '4', label: 'BOC', icon: 'boc.svg' },
        { key: 'bp', value: '5', label: 'BP', icon: 'bp.svg' },
        { key: 'burk', value: '39', label: 'Burk', icon: 'burk.svg' },
        { key: 'caltex', value: '6', label: 'Caltex', icon: 'caltex.svg' },
        //{key: 'caltex_woolworths', value: '19', label: 'Caltex Woolworths' },
        { key: 'cgl', value: '36', label: 'CGL Fuel', icon: 'cgl fuel.svg' },
        { key: 'coles_express', value: '20', label: 'Coles Express', icon: 'coles express.svg' },
        { key: 'costco', value: '32', label: 'Costco', icon: 'costco.svg' },
        { key: 'eagle', value: '24', label: 'Eagle', icon: 'eagle.svg' },
        { key: 'eg_ampol', value: '35', label: 'EG Ampol', icon: 'eg ampol.svg' },
        { key: 'fastfuel', value: '25', label: 'FastFuel 24/7', icon: 'fastfuel.svg' },
        { key: 'gull', value: '7', label: 'Gull', icon: 'gull.svg' },
        { key: 'independent', value: '15', label: 'Independent', icon: 'independent.svg' },
        //{key: 'kleenheat', value: '8', label: 'Kleenheat' },
        //{key: 'kwikfuel', value: '9', label: 'Kwikfuel' },
        { key: 'liberty', value: '10', label: 'Liberty', icon: 'liberty.svg' },
        { key: 'metro_petroleum', value: '30', label: 'Metro Petroleum', icon: 'metro petroleum.svg' },
        { key: 'mobil', value: '11', label: 'Mobil', icon: 'mobil.svg' },
        //{key: 'mogas', value: '33', label: 'Mogas' },
        { key: 'otr', value: '42', label: 'OTR', icon: 'otr.svg' },
        //{key: 'peak', value: '13', label: 'Peak' },
        { key: 'petro_fuels', value: '40', label: 'Petro Fuels', icon: 'petro fuels.svg' },
        { key: 'phoenix', value: '38', label: 'Phoenix', icon: '' },
        { key: 'puma', value: '26', label: 'Puma', icon: 'puma.svg' },
        { key: 'reddy_express', value: '43', label: 'Reddy Express', icon: 'reddy express.svg' },
        { key: 'shell', value: '14', label: 'Shell', icon: 'shell.svg' },
        { key: 'united', value: '23', label: 'United', icon: 'united.svg' },
        { key: 'vibe', value: '27', label: 'Vibe', icon: 'vibe.svg' },
        { key: 'wa_fuels', value: '31', label: 'WA Fuels', icon: 'wa fuels.svg' },
        //{ key: 'wesco', value: '16', label: 'Wesco', icon: '' },
        { key: 'xconv', value: '37', label: 'X Convenience', icon: 'x convenience.svg' },
    ];
}

const fuelRegionsData = () => {
    return [
        { key: 'metro_nor', value: '25', label: 'Metro North of River', type: 'metro', mapCenter: { lat: -31.8252, lng: 115.8725 } },
        { key: 'metro_sor', value: '26', label: 'Metro South of River', type: 'metro', mapCenter: { lat: -32.2538, lng: 115.8725 } },
        { key: 'metro_eh', value: '27', label: 'Metro East/Hills', type: 'metro', mapCenter: { lat: -31.9787, lng: 116.1767 } },
        { key: 'albany', value: '15', label: 'Albany', type: 'town', mapCenter: { lat: -35.0031, lng: 117.8654 } },
        { key: 'augusta_margaret_river', value: '28', label: 'Augusta / Margaret River', type: 'town', mapCenter: { lat: -33.9536, lng: 115.0739 } },
        { key: 'bridgetown_greenbushes', value: '30', label: 'Bridgetown / Greenbushes', type: 'town', mapCenter: { lat: -33.955, lng: 116.1339 } },
        { key: 'boulder', value: '1', label: 'Boulder', type: 'town', mapCenter: { lat: -30.8014, lng: 121.4637 } },
        { key: 'broome', value: '2', label: 'Broome', type: 'town', mapCenter: { lat: -17.9614, lng: 122.2359 } },
        { key: 'bunbury', value: '16', label: 'Bunbury', type: 'town', mapCenter: { lat: -33.3271, lng: 115.6414 } },
        //{ key: 'busselton_townsite', value: '3', label: 'Busselton (Townsite)', type: 'town', mapCenter: { lat: -33.6471, lng: 115.3452 } },
        { key: 'busselton_shire', value: '29', label: 'Busselton', type: 'town', mapCenter: { lat: -33.6471, lng: 115.3452 } },
        { key: 'capel', value: '19', label: 'Capel', type: 'town', mapCenter: { lat: -33.5505, lng: 115.5661 } },
        { key: 'carnarvon', value: '4', label: 'Carnarvon', type: 'town', mapCenter: { lat: -24.8840, lng: 113.6582 } },
        { key: 'cataby', value: '33', label: 'Cataby', type: 'town', mapCenter: { lat: -30.7361, lng: 115.5391 } },
        { key: 'collie', value: '5', label: 'Collie', type: 'town', mapCenter: { lat: -33.3629, lng: 116.1549 } },
        { key: 'coolgardie', value: '34', label: 'Coolgardie', type: 'town', mapCenter: { lat: -30.9538, lng: 121.1665 } },
        { key: 'cunderdin', value: '35', label: 'Cunderdin', type: 'town', mapCenter: { lat: -31.65, lng: 117.2342 } },
        { key: 'donnybrook_balingup', value: '31', label: 'Donnybrook / Balingup', type: 'town', mapCenter: { lat: -33.5828, lng: 115.8307 } },
        { key: 'dalwallinu', value: '36', label: 'Dalwallinu', type: 'town', mapCenter: { lat: -30.2778, lng: 116.6585 } },
        { key: 'dampier', value: '6', label: 'Dampier', type: 'town', mapCenter: { lat: -20.6537, lng: 116.7014 } },
        { key: 'dardanup', value: '20', label: 'Dardanup', type: 'town', mapCenter: { lat: -33.3669, lng: 115.7689 } },
        { key: 'denmark', value: '37', label: 'Denmark', type: 'town', mapCenter: { lat: -34.959, lng: 117.3582 } },
        { key: 'derby', value: '38', label: 'Derby', type: 'town', mapCenter: { lat: -17.3102, lng: 123.6495 } },
        { key: 'dongara', value: '39', label: 'Dongara', type: 'town', mapCenter: { lat: -29.2639, lng: 114.9389 } },
        { key: 'esperance', value: '7', label: 'Esperance', type: 'town', mapCenter: { lat: -33.8587, lng: 121.8932 } },
        { key: 'exmouth', value: '40', label: 'Exmouth', type: 'town', mapCenter: { lat: -21.929, lng: 114.1233 } },
        { key: 'fitzroy_crossing', value: '41', label: 'Fitzroy Crossing', type: 'town', mapCenter: { lat: -18.178, lng: 125.571 } },
        { key: 'geraldton', value: '17', label: 'Geraldton', type: 'town', mapCenter: { lat: -28.7793, lng: 114.614 } },
        { key: 'greenough', value: '21', label: 'Greenough', type: 'town', mapCenter: { lat: -28.883, lng: 114.7515 } },
        { key: 'harvey', value: '22', label: 'Harvey', type: 'town', mapCenter: { lat: -33.0846, lng: 115.8919 } },
        { key: 'jurien', value: '42', label: 'Jurien Bay', type: 'town', mapCenter: { lat: -30.3097, lng: 115.0423 } },
        { key: 'kalgoorlie', value: '8', label: 'Kalgoorlie', type: 'town', mapCenter: { lat: -30.7476, lng: 121.4694 } },
        { key: 'kambalda', value: '43', label: 'Kambalda', type: 'town', mapCenter: { lat: -31.2015, lng: 121.6533 } },
        { key: 'karratha', value: '9', label: 'Karratha', type: 'town', mapCenter: { lat: -20.7309, lng: 116.846 } },
        { key: 'kellerberrin', value: '44', label: 'Kellerberrin', type: 'town', mapCenter: { lat: -31.6287, lng: 117.7182 } },
        { key: 'kojonup', value: '45', label: 'Kojonup', type: 'town', mapCenter: { lat: -33.8326, lng: 117.1562 } },
        //{ key: 'kondinin', value: '46', label: 'Kondinin', type: 'town', mapCenter: { lat: -32.5045, lng: 118.2795 } },
        { key: 'kununurra', value: '10', label: 'Kununurra', type: 'town', mapCenter: { lat: -15.7666, lng: 128.7382 } },
        //{ key: 'lancelin', value: '47', label: 'Lancelin', type: 'town', mapCenter: { lat: -31.011, lng: 115.3282 } },
        //{ key: 'laverton', value: '48', label: 'Laverton', type: 'town', mapCenter: { lat: -28.6269, lng: 122.4073 } },
        //{ key: 'leeman', value: '49', label: 'Leeman', type: 'town', mapCenter: { lat: -29.9439, lng: 114.9883 } },
        { key: 'mandurah', value: '18', label: 'Mandurah', type: 'town', mapCenter: { lat: -32.5266, lng: 115.7211 } },
        { key: 'manjimup', value: '32', label: 'Manjimup', type: 'town', mapCenter: { lat: -34.2409, lng: 116.1456 } },
        { key: 'meckering', value: '58', label: 'Meckering', type: 'town', mapCenter: { lat: -31.6283, lng: 117.0123 } },
        { key: 'meekatharra', value: '46', label: 'Meekatharra', type: 'town', mapCenter: { lat: -26.5868, lng: 118.4707 } },
        //{ key: 'merredin', value: '51', label: 'Merredin', type: 'town', mapCenter: { lat: -31.4789, lng: 118.2757 } },
        { key: 'moora', value: '47', label: 'Moora', type: 'town', mapCenter: { lat: -30.6400, lng: 116.0081 } },
        { key: 'mount_barker', value: '48', label: 'Mount Barker', type: 'town', mapCenter: { lat: -34.6342, lng: 117.6669 } },
        //{ key: 'mount_magnet', value: '53', label: 'Mount Magnet', type: 'town', mapCenter: { lat: -28.0546, lng: 117.8449 } },
        //{ key: 'mukinbudin', value: '54', label: 'Mukinbudin', type: 'town', mapCenter: { lat: -30.9142, lng: 118.2073 } },
        { key: 'munglinup', value: '47', label: 'Munglinup', type: 'town', mapCenter: { lat: -33.7013, lng: 120.8702 } },
        { key: 'murray', value: '23', label: 'Murray', type: 'town', mapCenter: { lat: -32.6276, lng: 115.8741 } },
        { key: 'narrogin', value: '11', label: 'Narrogin', type: 'town', mapCenter: { lat: -32.9311, lng: 117.1787 } },
        { key: 'newman', value: '49', label: 'Newman', type: 'town', mapCenter: { lat: -23.3506, lng: 119.7306 } },
        { key: 'norseman', value: '50', label: 'Norseman', type: 'town', mapCenter: { lat: -32.1990, lng: 121.7791 } },
        { key: 'north_bannister', value: '60', label: 'North Bannister', type: 'town', mapCenter: { lat: -32.5803, lng: 116.4487 } },
        { key: 'northam', value: '62', label: 'Northam', type: 'town', mapCenter: { lat: -31.6537, lng: 116.6625 } },
        //{ key: 'ongerup', value: '58', label: 'Ongerup', type: 'town', mapCenter: { lat: -33.9616, lng: 118.5032 } },
        //{ key: 'pemberton', value: '24', label: 'Pemberton', type: 'town', mapCenter: { lat: -34.4365, lng: 116.0313 } },
        //{ key: 'pingelly', value: '59', label: 'Pingelly', type: 'town', mapCenter: { lat: -32.5341, lng: 117.0859 } },
        //{ key: 'pinjarra', value: '25', label: 'Pinjarra', type: 'town', mapCenter: { lat: -32.6262, lng: 115.8759 } },
        { key: 'port_hedland', value: '13', label: 'Port Hedland', type: 'town', mapCenter: { lat: -20.3115, lng: 118.6135 } },
        { key: 'ravensthorpe', value: '51', label: 'Ravensthorpe', type: 'town', mapCenter: { lat: -33.5775, lng: 120.0495 } },
        { key: 'regans_ford', value: '57', label: 'Regans Ford', type: 'town', mapCenter: { lat: -30.9765, lng: 115.7014 } },
        //{ key: 'sandstone', value: '61', label: 'Sandstone', type: 'town', mapCenter: { lat: -28.2927, lng: 119.5066 } },
        //{ key: 'serpentine', value: '26', label: 'Serpentine', type: 'town', mapCenter: { lat: -32.3881, lng: 115.992 } },
        { key: 'south_hedland', value: '14', label: 'South Hedland', type: 'town', mapCenter: { lat: -20.4065, lng: 118.5982 } },
        { key: 'tammin', value: '53', label: 'Tammin', type: 'town', mapCenter: { lat: -31.6414, lng: 117.4869 } },
        //{ key: 'tom_price', value: '63', label: 'Tom Price', type: 'town', mapCenter: { lat: -22.6863, lng: 117.7886 } },
        //{ key: 'toodyay', value: '27', label: 'Toodyay', type: 'town', mapCenter: { lat: -31.5532, lng: 116.4766 } },
        //{ key: 'victoria_park', value: '64', label: 'Victoria Park', type: 'town', mapCenter: { lat: -31.985, lng: 115.9033 } },
        //{ key: 'wagin', value: '65', label: 'Wagin', type: 'town', mapCenter: { lat: -33.3053, lng: 117.3449 } },
        //{ key: 'wandering', value: '66', label: 'Wandering', type: 'town', mapCenter: { lat: -32.7641, lng: 116.566 } },
        //{ key: 'westonia', value: '67', label: 'Westonia', type: 'town', mapCenter: { lat: -31.3073, lng: 118.6389 } },
        //{ key: 'wickepin', value: '68', label: 'Wickepin', type: 'town', mapCenter: { lat: -32.7948, lng: 117.6646 } },
        { key: 'waroona', value: '24', label: 'Waroona', type: 'town', mapCenter: { lat: -32.8422, lng: 115.9222 } },
        { key: 'williams', value: '54', label: 'Williams', type: 'town', mapCenter: { lat: -33.0275, lng: 116.8783 } },
        { key: 'wubin', value: '55', label: 'Wubin', type: 'town', mapCenter: { lat: -30.1049, lng: 116.6312 } },
        { key: 'wundowie', value: '59', label: 'Wundowie', type: 'town', mapCenter: { lat: -31.7592, lng: 116.3870 } },
        //{ key: 'wiluna', value: '70', label: 'Wiluna', type: 'town', mapCenter: { lat: -26.5924, lng: 120.2237 } },
        //{ key: 'wongan_ballidu', value: '71', label: 'Wongan-Ballidu', type: 'town', mapCenter: { lat: -30.8782, lng: 116.7126 } },
        //{ key: 'wyalkatchem', value: '72', label: 'Wyalkatchem', type: 'town', mapCenter: { lat: -31.2606, lng: 117.3739 } },
        { key: 'york', value: '56', label: 'York', type: 'town', mapCenter: { lat: -31.8745, lng: 116.7693 } }
    ];
}

const fuelStateRegionsData = () => {
    return [
        { key: 'gascoyne', value: '1', label: 'Gascoyne', type: 'stateRegion', mapCenter: { lat: -24.9095, lng: 115.3225 } },
        { key: 'goldfields_esperance', value: '2', label: 'Goldfields-Esperance', type: 'stateRegion', mapCenter: { lat: -32.1990, lng: 121.7791 } },
        { key: 'great_southern', value: '3', label: 'Great Southern', type: 'stateRegion', mapCenter: { lat: -34.6342, lng: 117.6669 } },
        { key: 'kimberley', value: '4', label: 'Kimberley', type: 'stateRegion', mapCenter: { lat: -17.9614, lng: 122.2359 } },
        { key: 'mid_west', value: '5', label: 'Mid-West', type: 'stateRegion', mapCenter: { lat: -26.5096, lng: 120.2558 } },
        { key: 'peel', value: '6', label: 'Peel', type: 'stateRegion', mapCenter: { lat: -32.6276, lng: 115.8759 } },
        { key: 'pilbara', value: '7', label: 'Pilbara', type: 'stateRegion', mapCenter: { lat: -23.3506, lng: 119.7306 } },
        { key: 'south_west', value: '8', label: 'South-West', type: 'stateRegion', mapCenter: { lat: -33.955, lng: 116.1339 } },
        { key: 'wheatbelt', value: '9', label: 'Wheatbelt', type: 'stateRegion', mapCenter: { lat: -31.6414, lng: 117.4869 } },
        { key: 'metro', value: '98', label: 'Metro All', type: 'metro', mapCenter: DATA_CONST.defaultMapCenter }
    ];
};

const getDataLabel = (dataType, dataValue) => {
    if (dataType === 'fuelType') {
        return fuelTypesData().find(f => f.value === dataValue).label;
    }

    if (dataType === 'fuelBrand') {
        return fuelBrandsData().find(f => f.value === dataValue).label;
    }

    // fuelRegion param has a format of [regionType].[regionValue], e.g. stateRegion.3 or town.8 or metro.25
    // refer to SearchBar component for more 
    if (dataType === 'fuelRegion') {
        const fuelRegionParts = dataValue.split('.');
        return [...fuelRegionsData(), ...fuelStateRegionsData()].find(f => f.type === fuelRegionParts[0] && f.value === fuelRegionParts[1]).label;
    }

    if (dataType === 'surrounding') {
        return dataValue ? 'Yes' : 'No';
    }
};

const getAveragePrice = (data) => {
    if (!data || !Array.isArray(data))
        return 'N/A';

    const prices = data.map(d => d.price);
    const sum = prices.reduce((p, c) => p + c, 0);
    if (!sum)
        return 'N/A';
    return (Math.round(sum / prices.length * 10) / 10).toFixed(1) || 'N/A';
};

const getMinPrice = (data) => {
    if (!data || !Array.isArray(data))
        return 'N/A';

    const prices = data.map(d => d.price);
    return prices.length === 0 ? 'N/A' : Math.min(...prices).toFixed(1);
};

const getMaxPrice = (data) => {
    if (!data || !Array.isArray(data))
        return 'N/A';

    const prices = data.map(d => d.price);
    return prices.length === 0 ? 'N/A' : Math.max(...prices).toFixed(1);
};

export {
    fetchFuelPricesData, fetchAppVersion, fuelTypesData, fuelBrandsData,
    fuelRegionsData, fuelStateRegionsData, getDataLabel, getAveragePrice,
    getMinPrice, getMaxPrice
};