import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

import { appSettings } from '../constants/setting-defaults';
import { getAveragePrice, getMinPrice, getMaxPrice} from '../services/data-service';
import i18next from '../i18n';

export default function InfoCard(props) {
    return (
        <>
            <Alert variant='standard' severity='info' color='success' sx={{ mx: {xs: 0, sm: 1}, px: {xs: 0.5, sm: 1}}}>
                <Box sx={{display: 'flex', alignItems:'center', gap: {xs: 1, sm: 1, md: 2}, flexDirection: 'row'}}>
                    <Box>
                        {props.loaded ? 
                            (<>
                                <Typography variant='span' color='text.primary' sx={{pr: {xs: 0.5, sm: 1}}}>{i18next.t('alert.info.price.avg')}</Typography>
                                <Typography variant='span' component='strong'>{getAveragePrice(props.data)}</Typography>  
                            </>) :
                            (<Skeleton animation="wave" variant="rounded" sx={{width: {xs: '25vw', sm: '20vw', md: '10vw'}}} />)
                        }
                    </Box>
                    <Box>
                        {props.loaded ? 
                            (<>
                                <Typography variant='span' color='text.primary' sx={{pr: {xs: 0.5, sm: 1}}}>{i18next.t('alert.info.price.min')}</Typography>
                                <Typography variant='span' component='strong'>{getMinPrice(props.data)}</Typography>
                            </>) :
                            (<Skeleton animation="wave" variant="rounded" sx={{width: {xs: '25vw', sm: '20vw', md: '10vw'}}} />)
                        }
                    </Box>
                    <Box>
                        {props.loaded ? 
                            (<>
                                <Typography variant='span' color='text.primary' sx={{pr: {xs: 0.5, sm: 1}}}>{i18next.t('alert.info.price.max')}</Typography>
                                <Typography variant='span' component='strong'>{getMaxPrice(props.data)}</Typography>
                            </>) :
                            (<Skeleton animation="wave" variant="rounded" sx={{width: {xs: '25vw', sm: '20vw', md: '10vw'}}} />)
                        }
                    </Box>
                </Box>
            </Alert>
            {props.data && props.data.length > 0 && (
                <Alert variant='standard' severity='info' sx={{ my: {xs: 0.25, sm: 0.5}, mx: {xs: 0, sm: 1}, px: {xs: 0.5, sm: 1}}}>
                    <Box>
                        <Box>
                            {props.loaded ? 
                                (
                                    <Typography variant="subtitle2" component='p'>{i18next.t('subtitle.cpl')}</Typography>
                                ) :
                                (<Skeleton animation="wave" variant="rounded" sx={{width: {xs: '80vw', sm: '60vw', md: '30vw'}}} />)
                            }
                        </Box>
                        {props.defaultView === appSettings.defaultView.table && (
                            <>
                                <Box>
                                    {props.loaded ? 
                                        (
                                            <Typography variant="subtitle2" component='p'>{i18next.t('subtitle.station.click')}</Typography>
                                        ) :
                                        (<Skeleton animation="wave" variant="rounded" sx={{width: {xs: '70vw', sm: '50vw', md: '25vw'}, my: 1}} />)
                                    }
                                </Box>
                                {props.locationTarget === appSettings.locationTarget.suburb &&
                                    <Box>
                                        {props.loaded ? 
                                            (
                                                <Typography variant="subtitle2" component='p'>{i18next.t('subtitle.suburb.click')}</Typography>
                                            ) :
                                            (<Skeleton animation="wave" variant="rounded" sx={{width: {xs: '60vw', sm: '40vw', md: '20vw'}}}/>)
                                        }
                                    </Box>
                                }
                            </>
                        )}
                    </Box>
                </Alert>
            )}
        </>
    );
}