export default class GtagEventService {
    static logException(errorMessage, isFatal) {
        window.gtag('event', 'exception', {
            description: errorMessage,
            fatal: isFatal
        });
    }

    static logSearch(searchParams) {
        window.gtag('event', 'search', {
            search_term: JSON.stringify(searchParams)
        });
    }

    static logViewSearchResults(searchParams) {
        window.gtag('event', 'view_search_results', {
            search_term: JSON.stringify(searchParams)
        });
    }

    static logCustomEvent(eventName, eventParams) {
        if (eventParams)
            window.gtag('event', eventName, eventParams);
        else
            window.gtag('event', eventName);
    }
}