import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';

import i18next from '../i18n';

function Donate() {
  return (
    <Box variant="body2" color="text.secondary" align="center">
      <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank">
        <input type="hidden" name="cmd" value="_donations" />
        <input type="hidden" name="business" value="KCUHR7WRDM8EG" />
        <input type="hidden" name="currency_code" value="AUD" />
        <input type="image" id="donate_button"
          src="https://www.paypalobjects.com/en_AU/i/btn/btn_donate_SM.gif" 
          border="0" name="submit" 
          title="If you find the site helpful, please consider a small donation." 
          alt="Donate with PayPal button" 
        />
      </form>
      <Typography variant="body2" color="text.primary">{i18next.t('footer.donate')} &#128150;</Typography>
    </Box>
  );
}

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {i18next.t('footer.copyright')} © {new Date().getFullYear()}
    </Typography>
  );
}

function Version() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {i18next.t('footer.version')} {process.env['REACT_APP_VERSION']}
    </Typography>
  );
}

function DataCourtesy() {
  return (
    <Typography
        variant="body2"
        align="center"
        color="text.secondary"
        component="p"
      >
        {i18next.t('footer.dataCourtesy')}&nbsp;
        <Link color='primary' href="https://fuelwatch.wa.gov.au" target='_blank'>
          <strong>FuelWatch</strong>
        </Link>
      </Typography>
  );
}

function Footer() {
  return (
    <Box component="footer" sx={{pb: 2}}>
      <Divider />

      <Box sx={{mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <LocalGasStationIcon />
        <Typography variant="body2" sx={{fontSize: { xs: '1rem', sm: '1.125rem', md: '1.25rem' }}}>
          {i18next.t('site.title')}
        </Typography>
      </Box>

      <Copyright />
      <Version />
      <Donate />
      <DataCourtesy />
    </Box>
  );
}

export default Footer;