import { useState, useEffect } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useOutletContext, useNavigate } from "react-router-dom";
import { LineChart } from '@mui/x-charts/LineChart';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import CircularProgress from '@mui/material/CircularProgress';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { appSettings } from '../constants/setting-defaults';
import { DATA_CONST } from '../constants/data-constants';
import { GTAG_EVENTS } from '../constants/gtag-events';
import { toDateString } from '../services/date-service';
import { formatUrl } from '../services/url-service';
import { fuelTypesData } from '../services/data-service';
import { initAppSettings } from '../services/caching-service';
import GtagEventService from '../services/gtag-service';

import i18next from '../i18n';

const fuelTypes = fuelTypesData();
const chartApiUrl = process.env.NODE_ENV === 'production' ? process.env['REACT_APP_FPWA_CHART_API'] : DATA_CONST.localApis.chart;

const fetchData = async (url) => {
    return await (await fetch(url)).json();
};

function ChartToolbar(props) {
    return (
        <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ mt: 4, md: {alignItems: 'center'}, justifyContent: 'center' }}>
            <FormControl size='small' sx={{ m: { xs: 0.5 }, minWidth: { sm: '20%' } }}>
                <InputLabel id="product-options">{i18next.t('chart.toolbar.productOptions')}</InputLabel>
                <Select
                    labelId="product-options"
                    id="product-options-select"
                    value={props.selectedProductOption}
                    label={i18next.t('chart.toolbar.productOptions')}
                    onChange={props.onProductOptionSelected}
                >
                    {fuelTypes.map(f =>
                        <MenuItem value={f.value} key={f.key}>{i18next.t(f.label)}</MenuItem>
                    )}
                </Select>
            </FormControl>
            <FormControl size='small' sx={{ m: { xs: 0.5 }, minWidth: { sm: '20%' } }}>
                <InputLabel id="date-options">{i18next.t('chart.toolbar.dateOptions')}</InputLabel>
                <Select
                    labelId="date-options"
                    id="date-options-select"
                    value={props.selectedDateOption}
                    label={i18next.t('chart.toolbar.dateOptions')}
                    onChange={props.onDateOptionSelected}
                >
                    {DATA_CONST.chartDateOptions.map(f =>
                        <MenuItem value={f.value} key={f.value}>{i18next.t(f.text)}</MenuItem>
                    )}
                </Select>
            </FormControl>
        </Stack>
    )
}

function PlotLineChart(props) {
    const dates = props.data.map(d => d.date);
    const prices = props.data.map(d => d.price);
    const product = props.data.map(d => d.product)[0];
    const minYaxis = Math.floor(Math.min(...prices) / 10) * 10;
    const maxYaxis = Math.ceil(Math.max(...prices) / 10) * 10;
    const isSmallScreen = useMediaQuery(props.theme.breakpoints.down('sm'));

    return props.loaded
        ? (
            <Box sx={{ width: {xs:'108%', sm:'100%'}, height: isSmallScreen ? '35vh' : '50vh'}}>
                <LineChart
                    xAxis={[{ scaleType: 'band', data: dates }]}
                    yAxis={[{ min: minYaxis, max: maxYaxis }]}
                    series={[
                        {
                            data: prices, label: product, area: true, color: '#76ab76'
                        },
                    ]}
                />
            </Box>
        )
        : (
            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', my: 4 }}>
                <CircularProgress color="secondary" sx={{ my: 1 }} /> <Typography color='GrayText'>{i18next.t('chart.loading')}</Typography>
            </Box>
        );
}

function RenderDataTable(props) {
    const isSmallScreen = useMediaQuery(props.theme.breakpoints.down('sm'));
    const cols = [
        {
            field: 'product',
            headerName: i18next.t('table.head.product'),
            sortable: false,
            align: 'center',
            headerAlign: 'center',
            width: isSmallScreen ? 110 : 200
        },
        {
            field: 'date',
            headerName: i18next.t('table.head.date'),
            align: 'center',
            headerAlign: 'center',
            width: isSmallScreen ? 100 : 200
        },
        {
            field: 'price',
            headerName: i18next.t('table.head.price'),
            type: 'number',
            align: 'center',
            headerAlign: 'center',
            width: isSmallScreen ? 100 : 200
        },
        {
            field: 'brand',
            headerName: i18next.t('table.head.brand'),
            align: 'center',
            headerAlign: 'center',
            width: isSmallScreen ? 150 : 350
        },
        {
            field: 'location',
            headerName: i18next.t('table.head.suburb'),
            align: 'center',
            headerAlign: 'center',
            width: isSmallScreen ? 150 : 200
        }
    ];
    const rows = props.data.map((p, i) => {
        return {
            id: i,
            product: p.product,
            date: p.date,
            price: p.price,
            brand: p.brand,
            location: p.location
        };
    });

    return (
        <Box sx={{ width: '98%', m: { xs: 0.5, sm: 1 } }}>
            <Typography variant="h6" gutterBottom sx={{ m: { xs: 0.5, sm: 1 }, fontSize: { xs: '1rem', sm: '1.125rem', md: '1.25rem' } }}>{i18next.t('chart.table.heading')}</Typography>
            <DataGrid
                rows={rows}
                columns={cols}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10
                        }
                    },
                }}
                pageSizeOptions={[5, 10, 20]}
                disableRowSelectionOnClick
                density={isSmallScreen ? 'comfortable' : 'comfortable'}
                loading={!props.loaded}
                sx={{
                    boxShadow: 2, 
                    border: 1, 
                    borderColor: 'GrayText',
                    '& .MuiDataGrid-cell:hover': {
                        color: 'primary.main',
                    },
                    m: { xs: 0, sm: 2 }
                }}
            />
        </Box>
    );
}

export default function ChartView() {
    const defaultPreferredFuelTypeState = initAppSettings()?.preferredFuelType || appSettings.preferredFuelType.value;
    const defaultChartDateOption = DATA_CONST.chartDateOptions[2].value;
    const [selectedDateOption, setSelectedDateOption] = useState(defaultChartDateOption); //Last 30 days
    const [selectedProductOption, setSelectedProductOption] = useState(defaultPreferredFuelTypeState); //ULP
    const [chartData, setChartData] = useState([]);
    const [dateFrom, setDateFrom] = useState(toDateString(defaultChartDateOption));
    const [dateTo, setDateTo] = useState(toDateString());
    const [loaded, setLoaded] = useState(false);
    const theme = useOutletContext();
    const navigate = useNavigate();
    

    const url = formatUrl(chartApiUrl, { from: dateFrom, to: dateTo, pid: selectedProductOption });

    const onDateOptionSelected = (e) => {
        setSelectedDateOption(e.target.value);
        setDateFrom(toDateString(e.target.value));
        setDateTo(toDateString());
        GtagEventService.logCustomEvent(GTAG_EVENTS.chart.toolbar.date, { date: e.target.value });
    };

    const onProductOptionSelected = (e) => {
        setSelectedProductOption(e.target.value);
        GtagEventService.logCustomEvent(GTAG_EVENTS.chart.toolbar.product, { product: e.target.text });
    };

    useEffect(() => {
        setLoaded(false);
        fetchData(url).then(d => {
            setChartData(d);
        }).catch((e) => {
            GtagEventService.logException(e.message, true);
        }).finally(() => {
            setLoaded(true);
        });
    }, [url, dateFrom, dateTo, selectedProductOption]);

    return (
        <>
            <Button variant='text' size="small" startIcon={<ArrowBackIcon />} onClick={() => navigate(-1)} sx={{ m: { xs: 0.25, sm: 1 }, justifyContent: 'flex-start'}}>{i18next.t('chart.action.return.home')}</Button>
            <Typography variant="h2" sx={{ textAlign: 'center', mt: 2.5, fontSize: { xs: '1rem', sm: '1.125rem', md: '1.25rem' } }} gutterBottom>{i18next.t('chart.title')}</Typography>
            <Typography variant="body2" color='text.secondary' sx={{ textAlign: 'center', fontSize: { xs: '0.65rem', sm: '0.8rem', md: '0.9rem' } }} gutterBottom>({i18next.t('chart.subtitle')})</Typography>
            <ChartToolbar
                selectedDateOption={selectedDateOption}
                selectedProductOption={selectedProductOption}
                onDateOptionSelected={onDateOptionSelected}
                onProductOptionSelected={onProductOptionSelected}
            />
            <PlotLineChart
                data={chartData}
                product={selectedProductOption}
                theme={theme}
                loaded={loaded}
            />
            <RenderDataTable
                data={chartData}
                theme={theme}
                loaded={loaded}
            />
            <Box sx={{ m: { xs: 0.25, sm: 1 }, pt: 4 }}>
                <Typography variant="h2" gutterBottom sx={{ m: { xs: 0.5, sm: 1 }, fontSize: { xs: '1rem', sm: '1.125rem', md: '1.25rem' } }}>{i18next.t('chart.resources.heading')}</Typography>
                <List>
                    <ListItem>
                        <Button size='small' href='https://www.fuelwatch.wa.gov.au/retail/weekly' target='_blank' startIcon={<SummarizeIcon />}>{i18next.t('chart.resources.report.weekly')}</Button>
                    </ListItem>
                    <ListItem>
                        <Button size='small' href='https://www.fuelwatch.wa.gov.au/retail/monthly' target='_blank' startIcon={<SummarizeIcon />}>{i18next.t('chart.resources.report.monthly')}</Button>
                    </ListItem>
                    <ListItem>
                        <Button size='small' href='https://www.fuelwatch.wa.gov.au/retail/historic' target='_blank' startIcon={<SummarizeIcon />}>{i18next.t('chart.resources.report.historical')}</Button>
                    </ListItem>
                </List>
            </Box>
            
        </>
    );
}