import { createBrowserRouter } from 'react-router-dom';
import App from '../App';
import ChartView from '../components/ChartView';
import ErrorView from '../components/ErrorView';

export const router = createBrowserRouter([
{
    path: '/',
    element: <App />,
    errorElement: <ErrorView />,
    children: [
        {
            path: 'chart',
            element: <ChartView />
        }
    ]
}]);
