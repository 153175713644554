export const GTAG_EVENTS = {
    settings: {
        panelState: 'settings_panelState',
        searchScope: 'settings_searchScope',
        view: 'settings_view',
        theme: 'settings_theme',
        language: 'settings_language'
    },
    table: {
        inline: {
            suburbLink: 'table_inline_suburbLink'
        },
        pagination: {
            pageIndex: 'table_pagination_pageIndex',
            pageSize: 'table_pagination_pageSize'
        },
        row: {
            moreActions: 'table_row_moreActions',
            expand: 'table_row_expand',
            collapse: 'table_row_collapse'
        }
    },
    search: {
        reset: 'search_reset'
    },
    map: {
        marker: {
            click: 'map_marker_click'
        }
    },
    version: 'new_version_reload',
    tab: 'day_tab_change',
    directions: 'directions_googleMaps',
    notifications: {
        popupState: 'notif_popup_state'
    },
    chart: {
        toolbar: {
            product: 'chart_toolbar_product_select',
            date: 'chart_toolbar_date_select'
        }
    }
};