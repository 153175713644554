import { createTheme } from '@mui/material/styles';
import { appSettings } from '../constants/setting-defaults';
import { zhCN } from '../constants/localisation';

export const loadTheme = (isDarkMode, language) => {
    return createTheme({
        palette: {
            background: {
                searchBar: { light: '#f2f2f2', dark: '#121212' },
                footer: { light: '#fcfdf9', dark: '#121212' },
                mapInfoPopup: { light: '#fff' },
                mapLoadingPopup: '#fcfcfc',
                infoTableHeader: { light: '#f4f9ff', dark: '#232323'},
                infoTableBody: {light: '#fefeff', dark: '#1a1a1a'}
            },
            color: {
                mapInfoPopup: {
                    text: '#3c3c3c',
                    link: '#3498ea',
                    highlightText: '#bb0909'
                },
                infoTable: {
                    price: {light: '#a90000', dark: '#ff8181'}
                }
            },
            mode: isDarkMode ? appSettings.backgroundMode.dark : appSettings.backgroundMode.light,
        }
    }, language === appSettings.language.zh ? zhCN : null);
}
