import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import i18next from '../../i18n';

export default function SharedTableHead(props) {
    return (
        <TableHead sx={{bgcolor: props.isDarkMode ? 'background.infoTableHeader.dark' : 'background.infoTableHeader.light'}}>
            <TableRow 
                sx={{ 
                    '& > th': {
                        textTransform: 'uppercase', 
                        fontWeight: 'bold'
                    },
                    '& > th:first-of-type': { pr: {xs: 0} }
                }}
            >
                <TableCell>{i18next.t('table.head.price')}</TableCell>
                <TableCell sx={{px: {xs: 0.5}}}>{i18next.t('table.head.station')}</TableCell>
                <TableCell sx={{px: {xs: 0.5}}}>{i18next.t('table.head.address')}</TableCell>
                <TableCell sx={{px: {xs: 0.125}}}>{i18next.t('table.head.suburb')}</TableCell>
                <TableCell sx={{minWidth: '2rem'}}></TableCell>
            </TableRow>
        </TableHead>
    )
}