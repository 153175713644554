import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Collapse from '@mui/material/Collapse';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import PhoneIcon from '@mui/icons-material/Phone';
import DescriptionIcon from '@mui/icons-material/Description';
import DirectionsIcon from '@mui/icons-material/Directions';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import { Button, Typography } from '@mui/material';

import { appSettings } from '../constants/setting-defaults';
import { gMapsDirUrl } from '../services/url-service';
import GtagEventService from '../services/gtag-service';
import RowMenu from './RowMenu';
import SharedTableHead from './shared/TableHead';

import { GTAG_EVENTS } from '../constants/gtag-events';
import { useTranslation } from 'react-i18next';

const rowsPerPageOptions = [10, 20, 50];

export default function InfoTable(props) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();

    // Avoid a layout jump when reaching the last page with empty rows.
    // const emptyRows =
    // page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.data.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        GtagEventService.logCustomEvent(GTAG_EVENTS.table.pagination.pageIndex, {number: newPage});
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        GtagEventService.logCustomEvent(GTAG_EVENTS.table.pagination.pageSize, {number: parseInt(event.target.value, 10)});
    };

    const handleExpandRow = (e) => {
        setOpen(true);
        GtagEventService.logCustomEvent(GTAG_EVENTS.table.row.expand);
    };

    const handleCollapseRow = (e) => {
        setOpen(false);
        GtagEventService.logCustomEvent(GTAG_EVENTS.table.row.collapse);
    };

    return  (
        <Stack spacing={2} alignItems="center" sx={{mb: 4}}>
            <TableContainer>
                <Table>
                    <SharedTableHead isDarkMode={props.isDarkMode} />
                    <TableBody sx={{bgcolor: props.isDarkMode ? 'background.infoTableBody.dark' : 'background.infoTableBody.light'}}>
                    { 
                        (rowsPerPage > 0
                            ? props.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : props.data
                        ).map((row) => (
                            <React.Fragment key={row.station}>
                                <TableRow
                                    hover
                                    sx={{ 
                                        '& > td': { borderBottom: 'none' }, 
                                        '& > td:last-child': { paddingLeft: {xs: 0} }
                                    }}
                                >
                                    <TableCell sx={{fontSize: {xs: '0.9rem', sm: 'unset'}}}>
                                        <Typography variant='span' sx={{color: props.isDarkMode ? 'color.infoTable.price.dark' : 'color.infoTable.price.light', fontWeight: 'bold'}}>
                                            {row.price}
                                        </Typography> 
                                    </TableCell>
                                    <TableCell sx={{px: {xs: 0.5}}}>
                                        <Link color='primary' underline='none' href={gMapsDirUrl(row.station)} target='_blank' rel="noopener" title={t('subtitle.station.click')}>{row.station}</Link>
                                    </TableCell>
                                    <TableCell sx={{px: {xs: 0.5}}}>{row.address}</TableCell>
                                    <TableCell sx={{px: {xs: 0.125}}}>
                                        {props.locationTarget === appSettings.locationTarget.suburb ? 
                                            (
                                                <Button color='secondary' variant='text' onClick={props.onSuburbCellClick} value={row.suburb} size='small' sx={{fontWeight: 'bold', textAlign: 'left'}}>
                                                    {row.suburb}
                                                </Button>
                                            ) : 
                                            <Typography variant='span' color='text.secondary' sx={{ textTransform: 'uppercase' }}>{row.suburb}</Typography>
                                        }
                                    </TableCell>
                                    <TableCell>
                                        <RowMenu
                                            station={row.station}
                                            latitude={row.latitude} 
                                            longitude={row.longitude}
                                            handleExpandRow={handleExpandRow}
                                            handleCollapseRow={handleCollapseRow}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                                        <Collapse in={open} timeout="auto" unmountOnExit>
                                            <Grid container alignItems="center" spacing={1} sx={{my: '1rem'}}>
                                                <Grid container item alignItems="center">
                                                    <img src={`/images/${row.brand.toLowerCase()}.svg`} alt='logo' 
                                                        style={{width: '10%', minWidth: '80px', backgroundColor: 'white', padding: '5px', borderRadius: '5px'}} 
                                                    /> 
                                                </Grid>
                                                <Grid container item alignItems="center">
                                                    <LocalGasStationIcon fontSize='small' sx={{marginRight: '1rem'}} /> {row.brand}
                                                </Grid>
                                                <Grid container item alignItems="center">
                                                    <PhoneIcon fontSize='small' sx={{marginRight: '1rem'}} /> {row.phone}
                                                </Grid>
                                                <Grid container item alignItems="center">
                                                    <DescriptionIcon fontSize='small' sx={{marginRight: '1rem'}} /> {row.siteFeatures}
                                                </Grid>
                                                <Grid container item alignItems="center">
                                                    <DirectionsIcon fontSize='small' sx={{marginRight: '1rem'}} /> 
                                                    <Link href={gMapsDirUrl(row.station)} underline="none" target='_blank' rel="noopener">
                                                        {t('site.link.directions')}
                                                    </Link>
                                                </Grid>
                                            </Grid>
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        ))
                    }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                showFirstButton
                showLastButton
                labelRowsPerPage=""
                count={props.data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{padding: {xs: 0}}}
            />
        </Stack>
    );
}