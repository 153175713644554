import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SignpostIcon from '@mui/icons-material/Signpost';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import CloseIcon from '@mui/icons-material/Close';
import TableViewIcon from '@mui/icons-material/TableView';
import MapIcon from '@mui/icons-material/Map';
import TranslateIcon from '@mui/icons-material/Translate';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import { appSettings } from '../constants/setting-defaults';

import i18next from '../i18n';

export default function SettingsPanel(props) {
    const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

    return (
        <SwipeableDrawer
            disableBackdropTransition={!iOS} 
            disableDiscovery={iOS}
            anchor={props.anchor}
            open={props.isDrawerOpen}
            onOpen={props.onDrawerOpen}
            onClose={props.onDrawerClose}
          >
            <Box sx={{m: 3}}>
                <Stack direction={'row'}>
                    <Typography variant='h6'>{i18next.t('settings.title')}</Typography>
                    <IconButton
                        title={i18next.t('settings.close')}
                        onClick={props.onDrawerClose}
                        size='small'
                        sx={{marginLeft: 'auto'}}
                    >
                        <CloseIcon />
                    </IconButton>
                </Stack>
                <Divider sx={{my: 2}} />
                <Box sx={{mb: 2}}>
                    <Typography variant='subtitle1' gutterBottom>{i18next.t('settings.view')}</Typography>
                    <ToggleButtonGroup
                        exclusive
                        fullWidth
                        color="primary"
                        value={props.defaultView}
                    >
                        <ToggleButton value="table" onClick={props.onDefaultViewChange}><TableViewIcon sx={{mr: 1}} /> {i18next.t('settings.view.table')}</ToggleButton>
                        <ToggleButton value="map" onClick={props.onDefaultViewChange}><MapIcon sx={{mr: 1}} /> {i18next.t('settings.view.map')}</ToggleButton>
                    </ToggleButtonGroup>
                </Box>
                <Box sx={{mb: 2}}>
                    <Typography variant='subtitle1' gutterBottom>{i18next.t('settings.theme')}</Typography>
                    <ToggleButtonGroup
                        exclusive
                        fullWidth
                        color="primary"
                        value={props.backgroundMode}
                    >
                        <ToggleButton value="light" onClick={props.onBackgroundModeChange}><LightModeIcon sx={{mr: 1}} /> {i18next.t('settings.theme.light')}</ToggleButton>
                        <ToggleButton value="dark" onClick={props.onBackgroundModeChange}><DarkModeIcon sx={{mr: 1}} /> {i18next.t('settings.theme.dark')}</ToggleButton>
                    </ToggleButtonGroup>
                </Box>
                <Box sx={{mb: 2}}>
                    <Typography variant='subtitle1' gutterBottom>{i18next.t('settings.language')}</Typography>
                    <ToggleButtonGroup
                        exclusive
                        fullWidth
                        color="primary"
                        value={props.language}
                    >
                        <ToggleButton value={appSettings.language.en} onClick={props.onLanguageChange}><TranslateIcon sx={{mr: 1}} /> {i18next.t('settings.language.en')}</ToggleButton>
                        <ToggleButton value={appSettings.language.zh} onClick={props.onLanguageChange}><TranslateIcon sx={{mr: 1}} /> {i18next.t('settings.language.zh')}</ToggleButton>
                    </ToggleButtonGroup>
                </Box>
                <Box sx={{mb: 2}}>
                    <Typography variant='subtitle1' gutterBottom>{i18next.t('settings.searchScope')}</Typography>
                    <ToggleButtonGroup
                        exclusive
                        fullWidth
                        color="primary"
                        value={props.locationTarget}
                    >
                        <ToggleButton value="suburb" onClick={props.onLocationTargetChange}><SignpostIcon sx={{mr: 1}} /> {i18next.t('settings.searchScope.suburb')}</ToggleButton>
                        <ToggleButton value="metro" onClick={props.onLocationTargetChange}><LocationCityIcon sx={{mr: 1}} /> {i18next.t('settings.searchScope.metro')}</ToggleButton>
                        <ToggleButton value="region" onClick={props.onLocationTargetChange}><AgricultureIcon sx={{mr: 1}} /> {i18next.t('settings.searchScope.region')}</ToggleButton>
                    </ToggleButtonGroup>
                </Box>
                <Box sx={{mb: 2}}>
                    <Typography variant='subtitle1' gutterBottom>{i18next.t('settings.preferredFuelType')}</Typography>
                    <FormControl>
                        <RadioGroup
                            name="radioGroupFuelTypes"
                            value={props.preferredFuelType}
                            onChange={props.onFuelTypeChange}
                        >
                            {props.fuelTypes.map((t, i) => (
                                <FormControlLabel key={t.value} value={t.value} control={<Radio />} label={i18next.t(t.label)} />
                            ))}
                        </RadioGroup>
                    </FormControl>
                </Box>
            </Box>
        </SwipeableDrawer>
    );
}