import { DATA_CONST } from '../constants/data-constants';

/// calculate date based on the date options, e.g. last7days
/// then output as string in format of YYYY-MM-DD
function toDateString(dateOption) {
    var result = new Date();
    switch(dateOption) {
        case DATA_CONST.chartDateOptions[0].value:
            result = addDays(result, -6);
            break;
        case DATA_CONST.chartDateOptions[1].value:
            result = addDays(result, -13);
            break;
        case DATA_CONST.chartDateOptions[2].value:
            result = addDays(result, -29);
            break;
        case DATA_CONST.chartDateOptions[3].value:
            result = addMonths(result, -2);
            break;
        case DATA_CONST.chartDateOptions[4].value:
            result = addMonths(result, -5);
            break;
        case DATA_CONST.chartDateOptions[5].value:
            result = addMonths(result, -8);
            break;
        case DATA_CONST.chartDateOptions[6].value:
            result = addYears(result, -1);
            break;
        default:
            break;
    }
    
    const y = result.getFullYear();
    const m = result.getMonth() + 1;
    const d = result.getDate();
    return `${y}-${m.toString().padStart(2, '0')}-${d.toString().padStart(2, '0')}`;      
}

function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

function addMonths(date, months) {
    var result = new Date(date);
    result.setMonth(result.getMonth() + +months);
    return result;
}

function addYears(date, years) {
    var result = new Date(date);
    result.setFullYear(result.getFullYear() + years);
    return result;
}

export { toDateString };